import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ChurchEvent, getEvents } from '../../services';
import EventDetails from './EventDetails';
import { useParams } from 'react-router-dom';

const EventTab = ({ events }: { events: ChurchEvent[] }) => {
  return (
    <Box sx={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
      {events.map((e) => (
        <EventDetails key={e.id} event={e} />
      ))}
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Events = () => {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const [events, setEvents] = useState<ChurchEvent[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    getEvents().then(setEvents);
  }, []);

  const today = new Date();
  const upcomingEvents = events
    .filter((x) => x.startTime.getTime() >= today.getTime())
    .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

  const pastEvents = events
    .filter((x) => x.startTime.getTime() <= today.getTime())
    .sort((a, b) => b.startTime.getTime() - a.startTime.getTime());
  return (
    <Box component={'span'}>
      <div className="contactus-hero">Events</div>
      <Box sx={{ width: '100%' }}>
        <Box>
          {!id && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              indicatorColor="secondary"
              scrollButtons="auto"
            >
              <Tab label="Upcoming Events" {...a11yProps(0)} />
              <Tab label="Past Events" {...a11yProps(1)} />
            </Tabs>
          )}
        </Box>
        {!id && (
          <>
            <TabPanel value={value} index={0}>
              <EventTab events={upcomingEvents} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EventTab events={pastEvents} />
            </TabPanel>
          </>
        )}
        {id && <EventTab events={events.filter((x) => x.id === id)} />}
      </Box>
    </Box>
  );
};

export default Events;
