// src/components/About.tsx
import React, { useEffect } from 'react';

const SundayAnnouncements = () => {
  useEffect(() => {
    window.location.href =
      'https://docs.google.com/presentation/d/e/2PACX-1vSToQlRS_1qXiiPOce3kwV3Mj1B7ZbX_CM9UHPprPFhrb9dxpcbIlWkxgUTGGu8A9MJGihMROYxx7X9/pub?start=true&loop=true&delayms=5000';
  }, []);
  return <div>Sunday announcments</div>;
};

export default SundayAnnouncements;
