import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
  Container,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';
import { submitBreastForm } from '../../services';
import { Print, RemoveCircle } from '@mui/icons-material';
import { FormTextField } from '../custom/CustomComponents';

export interface IBreastFormData {
  survivor: string;
  firstName: string;
  lastName: string;
  // address: string;
  // city: string;
  // state: string;
  // zip: string;
  email: string;
  phone: string;
  tshirtSize: string;
  orderTshirt: boolean;
  gender?: string;
}

const BreastCancer = ({ form = null }: { form?: DocumentData | null }) => {
  const expired = false;
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState<IBreastFormData>(
    (form || {
      survivor: 'No',
      name: '',
      email: '',
      phone: '',
      tshirtSize: 'Large',
      orderTshirt: true,
      gender: 'Female',
    }) as IBreastFormData
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  //   const handleChange2 = (e: SelectChangeEvent<string>) => {
  //     const { name, value } = e.target;
  //     setState({
  //       ...state,
  //       [name]: value,
  //     });
  //   };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    submitBreastForm(state)
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { firstName, lastName } = state;
  const isDisabled = !firstName && !lastName;
  const formDisabled = form !== null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const sizes1 = ['Small', 'Medium', 'Large', 'Extra Large'];

  const sizes2 = ['2X', '3X', '4X'];
  const orderableItems = [
    { label: 'Caps', value: 'caps', disabled: true, cost: 0 },
    {
      label: 'Cap without rhinestones ($8)',
      value: 'cap',
      disabled: false,
      cost: 8,
    },
    {
      label: 'Cap with rhinestones ($10)',
      value: 'cap-r',
      disabled: false,
      cost: 10,
    },
    { label: 'Female T-shirts', value: 'fem', disabled: true, cost: 0 },
    ...sizes1.map((x) => ({
      label: `${x} (Female) ($12)`,
      value: `${x}`,
      disabled: false,
      cost: 12,
    })),
    ...sizes2.map((x) => ({
      label: `${x} (Female) ($14)`,
      value: `${x}`,
      disabled: false,
      cost: 14,
    })),
    { label: 'Male T-shirts', value: 'men', disabled: true, cost: 0 },
    ...sizes1.map((x) => ({
      label: `${x} (Male) ($12)`,
      value: `${x}-m`,
      disabled: false,
      cost: 12,
    })),
    ...sizes2.map((x) => ({
      label: `${x} (Male) ($14)`,
      value: `${x}-m`,
      disabled: false,
      cost: 14,
    })),
  ];

  /*
  const valsx: any = {
    Small: 10,
    Medium: 10,
    Large: 10,
    'X-Large': 10,
    '2X': 13,
    '3X': 13,
    '4X': 13,
    'Small-M': 10,
    'Medium-M': 10,
    'Large-M': 10,
    'X-Large-M': 10,
    '2X-M': 13,
    '3X-M': 13,
    '4X-M': 13,
  };*/

  const getTotalCost = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val: { [key: string]: number } = {};
    orderableItems.forEach((i) => {
      if (i.cost) {
        val[i.value] = i.cost;
      }
    });
    const l = state.tshirtSize.split(',');
    let total = 0;
    for (let i = 0; i < l.length; i++) {
      total += val[l[i]];
    }
    return total.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };
  if (expired) {
    return (
      <Dialog
        open={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Registration Capacity Reached{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for your interest in registering for our Tenth Annual
            Breast Health Awareness Community Seminar. We have reach capacity
            and registration is now closed. We hope to see you next year.
            Blessings to you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = '/';
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container
        sx={{
          width: {
            md: '50%',
            xs: '100%',
            // backgroundImage: `url(${require('../../images/breast_cancer_2024.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
          },
        }}
      >
        {formDisabled && (
          <div className="no-print">
            <Button variant="contained" startIcon={<Print />} onClick={print}>
              Print Completed Form
            </Button>
          </div>
        )}
        <div style={{ marginBottom: -15, marginTop: '0rem' }}>11th Annual</div>
        <h3>BREAST HEALTH AWARENESS COMMUNITY SEMINAR</h3>
        <h4 style={{ color: 'deeppink' }}>
          SATURDAY OCTOBER 12, 2024 | 8:00 AM - 11:30 AM
        </h4>
        <h4>Registration Form</h4>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Are you a breast cancer survivor?
            </FormLabel>
            <RadioGroup
              row
              name="survivor"
              value={state.survivor}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
                disabled={formDisabled}
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
                disabled={formDisabled}
              />
            </RadioGroup>
          </FormControl>
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            name="firstName"
            label="First Name"
            value={state.firstName}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            name="lastName"
            label="Last Name"
            value={state.lastName}
            onChange={handleChange}
          />

          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            name="email"
            label="E-mail"
            value={state.email}
            onChange={handleChange}
          />

          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            name="phone"
            label="Phone Number"
            value={state.phone}
            onChange={handleChange}
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="shirt-label">
              Would you like to order a T-Shirt or Cap?
            </InputLabel>
            <Select
              labelId="shirt-label"
              label="Would you like to order a T-Shirt or Cap?"
              name="orderTshirt"
              value={state.orderTshirt ? 'Yes' : 'No'}
              onChange={(x) =>
                setState((p) => ({
                  ...p,
                  ...{ orderTshirt: x.target.value === 'Yes' },
                }))
              }
              disabled={formDisabled}
            >
              <MenuItem value={'Yes'}>Yes</MenuItem>
              <MenuItem value={'No'}>No</MenuItem>
            </Select>
          </FormControl>

          {state.orderTshirt && (
            <div>
              <p>
                <i style={{ marginTop: '10px', color: 'gray' }}>
                  Seminar themed T-shirts & Caps available for donation prices
                  below:
                </i>
                <img
                  src={require('../../images/shirts.png')}
                  alt="t-shirts"
                  style={{ width: '100%' }}
                />
              </p>
              <p>Please select T-shirt size or Cap:</p>
              {state.tshirtSize.split(',').map((size, i) => {
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    key={`${size}${i}`}
                    sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}
                  >
                    <InputLabel id="size-label">Size</InputLabel>

                    <Select
                      labelId="size-label"
                      label="Size"
                      name="tshirtSize"
                      value={size}
                      onChange={(e) => {
                        setState((p) => {
                          const d = p.tshirtSize.split(',');
                          d[i] = e.target.value;
                          return { ...p, ...{ tshirtSize: d.join(',') } };
                        });
                      }}
                      disabled={formDisabled}
                      sx={{ flex: 1 }}
                    >
                      {orderableItems.map((item) => (
                        <MenuItem
                          key={item.value}
                          disabled={item.disabled}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                      {/* <MenuItem disabled>Female</MenuItem>

                      <MenuItem value={'Small'}>Small (Female) ($12)</MenuItem>
                      <MenuItem value={'Medium'}>
                        Medium (Female) ($12)
                      </MenuItem>
                      <MenuItem value={'Large'}>Large (Female)($12)</MenuItem>
                      <MenuItem value={'X-Large'}>
                        X-Large (Female) ($12)
                      </MenuItem>
                      <MenuItem value={'2X'}>2X (Female) ($14)</MenuItem>
                      <MenuItem value={'3X'}>3X (Female) ($14)</MenuItem>
                      <MenuItem value={'4X'}>4X (Female) ($14)</MenuItem>

                      <MenuItem disabled>Male</MenuItem>

                      <MenuItem value={'Small-M'} disabled>
                        Small (Male) ($10) (Sold-Out)
                      </MenuItem>
                      <MenuItem value={'Medium-M'} disabled>
                        Medium (Male) ($10) (Sold-Out)
                      </MenuItem>
                      <MenuItem value={'Large-M'}>Large (Male) ($10)</MenuItem>
                      <MenuItem value={'X-Large-M'} disabled>
                        X-Large (Male) ($10) (Sold-Out)
                      </MenuItem>
                      <MenuItem value={'2X-M'} disabled>
                        2X (Male) ($13) (Sold-Out)
                      </MenuItem>
                      <MenuItem value={'3X-M'} disabled>
                        3X (Male) ($13) (Sold-Out)
                      </MenuItem>
                      <MenuItem value={'4X-M'} disabled>
                        4X (Male) ($13) (Sold-Out)
                      </MenuItem> */}
                    </Select>

                    {/* {i !== 0 && ( */}
                    <IconButton
                      sx={{
                        display: i === 0 ? 'block' : 'initial',
                        visibility: i === 0 ? 'hidden' : 'visible',
                      }}
                      onClick={() => {
                        setState((p) => {
                          const d = p.tshirtSize.split(',');
                          d.splice(i, 1);
                          return { ...p, ...{ tshirtSize: d.join(',') } };
                        });
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                    {/* )} */}
                  </FormControl>
                );
              })}
              <Box sx={{ mt: 2, fontWeight: 'bold' }}>
                Total: {getTotalCost()}{' '}
              </Box>
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() =>
                  setState((p) => ({
                    ...p,
                    ...{ tshirtSize: `${p.tshirtSize},Small` },
                  }))
                }
              >
                + Add More
              </Button>

              <p>
                Online payments are accepted through{' '}
                <a
                  href="https://cash.app/$NMZLakeland"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cash App
                </a>
                . For other payment methods such as cash, checks, or money
                orders, please see a member of the NMZ Health and Wellness
                Ministry or contact the church office.
              </p>
            </div>
          )}

          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '0em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Registration Submitted
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thank you for registering
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </Box>
  );
};

export default BreastCancer;
