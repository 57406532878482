import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Grid,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { FormIds, submitForm } from '../../services';
import { RemoveCircle } from '@mui/icons-material';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';

interface Child {
  firstName: string;
  lastName: string;
  age: string;
  gradeLevel: string;
  school: string;
}
const ChildInputs = ({
  index,
  childState,
  handleChildInputChange,
  handleDeleteChild,
  isFilled,
}: {
  index: number;
  childState: Child;
  handleChildInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteChild: (index: number) => void;
  isFilled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        mr: 1,
        ml: 1,
        mt: 1,
        border: {
          xs: '1px solid #ccc',
          md: 'none',
        },
        //background: 'red',
        // width: '100vw',
      }}
    >
      <FormTextField
        label={`Child's First Name`}
        name={`firstName${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.firstName}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's Last Name`}
        name={`lastName${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.lastName}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's Age`}
        name={`age${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.age}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's Grade Level`}
        name={`gradeLevel${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.gradeLevel}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's School`}
        name={`school${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.school}
        isFilled={isFilled}
        disabled={isFilled}
      />
      {!isFilled && (
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteChild(index)}
        >
          <RemoveCircle />
        </IconButton>
      )}
    </Box>
  );
};

const SchoolBash = ({ form = null }: { form?: DocumentData | null }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const kids = !form
    ? [
        {
          firstName: '',
          lastName: '',
          age: '',
          gradeLevel: '',
          school: '',
        },
      ]
    : form.children;
  const [children, setChildren] = useState<Child[]>(kids);

  const addChild = () => {
    setChildren([
      ...children,
      {
        firstName: '',
        lastName: children[0].lastName,
        age: '',
        gradeLevel: '',
        school: '',
      },
    ]);
  };

  const handleChildInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedChildren = [...children];
    const prop = e.target.name.replace(`${index}`, '');
    if (prop === 'firstName') updatedChildren[index].firstName = e.target.value;
    if (prop === 'lastName') updatedChildren[index].lastName = e.target.value;
    if (prop === 'age') updatedChildren[index].age = e.target.value;
    if (prop === 'gradeLevel')
      updatedChildren[index].gradeLevel = e.target.value;
    if (prop === 'school') updatedChildren[index].school = e.target.value;
    setChildren(updatedChildren);
  };

  const handleDeleteChild = (index: number) => {
    const newChildren = [...children];
    newChildren.splice(index, 1);
    if (newChildren.length === 0) return;
    setChildren(newChildren);
  };
  const [values, setValues] = useState({
    lastName: form?.lastName ?? '',
    firstName: form?.firstName ?? '',
    cellPhone: form?.cellPhone ?? '',
    isMember: form?.isMember ?? '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    submitForm(FormIds.schoolBash2024, { ...values, ...{ children } })
      .then(() => {
        setOpen(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const { firstName, lastName, cellPhone } = values;
  const isDisabled = !(
    firstName &&
    lastName &&
    cellPhone &&
    cellPhone &&
    children.every((x) => x.firstName && x.age)
  );

  return (
    <Box sx={{ margin: '2rem' }}>
      <Container>
        {!form && (
          <a
            href={require(`../../images/school_bash_preview.png`)}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require(`../../images/school_bash_preview.png`)}
              className="ministry-details-image"
              style={{
                height: '300px',
                width: 'auto',
                margin: '1rem',
                cursor: 'pointer',
              }}
            />
          </a>
        )}
        <h3>BACK TO SCHOOL BASH 2024 (Jul 27, 2024, 10AM - 1PM)</h3>

        <h4>Register your Children</h4>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormTextField
                name="firstName"
                label="Parent/Guardian First Name"
                fullWidth
                onChange={handleChange}
                value={values.firstName}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormTextField
                name="lastName"
                label="Parent/Guardian Last Name"
                fullWidth
                onChange={handleChange}
                value={values.lastName}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormTextField
                name="cellPhone"
                label="Cell Phone Number"
                fullWidth
                onChange={handleChange}
                value={values.cellPhone}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isMember === 'true'}
                      onChange={(e) => {
                        setValues((p) => ({
                          ...p,
                          isMember: e.target.checked ? 'true' : 'false',
                        }));
                      }}
                    />
                  }
                  label="NMZ member"
                />
              </FormGroup>
              {/* <FormTextField
                name="isMember"
                label="Member of NMZ?"
                fullWidth
                onChange={handleChange}
                value={values.isMember}
                isFilled={form !== null}
                disabled={form !== null}
              /> */}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Grid>
                {children.map((x, index) => (
                  <ChildInputs
                    key={index}
                    index={index}
                    handleChildInputChange={(x) =>
                      handleChildInputChange(x, index)
                    }
                    handleDeleteChild={handleDeleteChild}
                    childState={x}
                    isFilled={form !== null}
                  />
                ))}
              </Grid>
              {!form && (
                <Button onClick={addChild} sx={{ ml: 3, mt: { xs: 1, md: 1 } }}>
                  Add another Child
                </Button>
              )}
            </Box>
          </Grid>
          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={open}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thank you for registering
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </Box>
  );
};

export default SchoolBash;
