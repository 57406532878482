// src/components/About.tsx

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import React, { useState } from 'react';
import { submitContactRequest } from '../../services';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';

const ContactForm = ({ form = null }: { form?: DocumentData | null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const options = [
    'New Members',
    'Photo Release',
    'Prayer Requests',
    'Benevolence',
    'Apply for facility use',
    'Other',
  ];
  const [firstName, setFirstName] = useState(form?.firstName ?? '');
  const [lastName, setLastName] = useState(form?.lastName ?? '');
  const [email, setEmail] = useState(form?.email ?? '');
  const [phone, setPhone] = useState(form?.phone ?? '');
  const [note, setNote] = useState(form?.note ?? '');
  const [selectedOption, setSelectedOption] = useState(
    form?.selectedOption ?? ''
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    submitContactRequest({
      firstName,
      lastName,
      email,
      phone,
      note,
      selectedOption,
    })
      .then(() => {
        setOpen(true);
        setIsLoading(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSelectedOption('');
        setNote('');
      })
      .catch(() => {
        setIsLoading(false);
        alert('Error Submitting');
      });
  };
  return (
    <Container>
      <div>
        <h2>Connect with NMZ Lakeland</h2>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: {
                md: '50vw',
                xs: '90vw',
              },
              marginBottom: 20,
            }}
          >
            <FormTextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={{ marginBottom: '10px' }}
              disabled={form !== null}
              isFilled={form !== null}
            />
            <FormTextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              style={{ marginBottom: '10px' }}
              disabled={form !== null}
              isFilled={form !== null}
            />
            <FormTextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              style={{ marginBottom: '10px' }}
              disabled={form !== null}
              isFilled={form !== null}
              inputProps={{
                inputMode: 'email',
                pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
                title: 'Please enter a valid email address',
              }}
            />
            <FormTextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
              required
              disabled={form !== null}
              isFilled={form !== null}
              style={{ marginBottom: '10px' }}
            />

            <FormControl style={{ marginBottom: '10px' }}>
              <Select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                displayEmpty
                required
                disabled={form !== null}
              >
                <MenuItem value="" disabled>
                  Reason for your Request{' '}
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormTextField
              label="Please take a moment to tell us a little more about your request"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              multiline
              rows={4}
              style={{ marginBottom: '10px' }}
              required
              disabled={form !== null}
              isFilled={form !== null}
            />
            {!form && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            )}
          </Box>
        </form>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          Message Sent. You will be contacted very soon
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
