import React from 'react';
import { useParams } from 'react-router-dom';
import { churchMinistries } from '../services';
import { colors } from '../utils';

const MinistryDetails = () => {
  const { id } = useParams();
  const ministry = churchMinistries.find((x) => x.id === id);

  if (!ministry) return null;
  return (
    <div className="ministry-details">
      <div className="ministry-details-name">{ministry.name}</div>
      <img
        src={require(`../images/ministries/${ministry.media[0]}`)}
        className="ministry-details-image"
      />
      <div
        style={{
          color: colors.themeRed,
          fontWeight: 'bold',
          fontSize: '1.5rem',
          padding: '1rem',
        }}
      >
        {ministry.description}
      </div>
      {ministry.content && (
        <div
          dangerouslySetInnerHTML={{ __html: ministry.content }}
          style={{ margin: '1rem', color: '#616161', fontSize: '1.3rem' }}
        ></div>
      )}
    </div>
  );
};

export default MinistryDetails;
