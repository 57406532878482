import React from 'react';
import { Box } from '@mui/system';
import { Tabs, Tab, Button } from '@mui/material';
import Forms from '../Forms/Forms';
import { useParams, useNavigate } from 'react-router-dom';
import Profile from './Profile';
import Gallery from './Gallery';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const pages = ['bulletins', 'photo-gallery', 'forms'];

const Members = () => {
  const { page } = useParams();
  const nav = useNavigate();

  const value = page ? pages.indexOf(page) + 1 : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    nav(`/members/${newValue === 0 ? '' : pages[newValue - 1]}`);
  };
  return (
    <Box component={'span'}>
      <div className="contactus-hero">Members</div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            scrollButtons="auto"
            // variant="scrollable"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Bulletins" {...a11yProps(1)} />

            <Tab label="Photos" {...a11yProps(2)} />
            <Tab label="Forms" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ul>
            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fnmz_july_bulletin.pdf?alt=media&token=fd016a99-702c-4ea6-a2d0-c5c622f931be"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View July 2024 Bulletin
              </Button>
            </li>
            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fmarch_2024_bulletin.pdf?alt=media&token=f51ae54a-89e0-4fb6-b95c-c129711ef394"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View March 2024 Bulletin
              </Button>
            </li>
            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2FFebruary%20bulletin.pdf?alt=media&token=b553e0d0-78cb-4534-9370-22e984d69934"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View Feb 2024 Bulletin
              </Button>
            </li>

            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fjan_2024_bulletin.pdf?alt=media&token=e57542d8-b402-4162-a5ee-17bc623773b5"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View January 2024 Bulletin
              </Button>
            </li>

            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fdecember_bulletin.pdf?alt=media&token=e223571a-8674-4bfb-a014-7a2476f2f2c0"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View December 2023 Bulletin
              </Button>
            </li>

            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fnovember_bulletin.pdf?alt=media&token=563f1dd1-87c9-4122-8846-25acec5e84f3&_gl=1*atgjeh*_ga*MTg2MjUyODk3Ny4xNjg3MjI5OTc1*_ga_CW55HF8NVT*MTcwMDE0NzYzNy45Ni4xLjE3MDAxNDc4MzEuNTkuMC4w"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View November 2023 Bulletin
              </Button>
            </li>
            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Foct_bulletin.pdf?alt=media&token=8576522f-629f-4949-a057-808e2b869fb8"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View October 2023 Bulletin
              </Button>
            </li>
            <li style={{ padding: '0.5rem' }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fsept_bulletin.pdf?alt=media&token=a34e7577-1b63-43fa-bdf4-bb8857d67dd6&_gl=1*120xdgi*_ga*MTg2MjUyODk3Ny4xNjg3MjI5OTc1*_ga_CW55HF8NVT*MTcwMDE0NzYzNy45Ni4xLjE3MDAxNDc5OTEuNjAuMC4w"
                target="_blank"
                color="primary"
                // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
                // sx={{ mt: 2, borderColor: '#fff' }}
              >
                View September Bulletin
              </Button>
            </li>
          </ul>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Gallery />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Forms />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Members;
