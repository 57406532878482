import * as React from 'react';
import logo from '../images/logo.png'; // Import your logo image here

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '../utils';
import { useNavigate } from 'react-router-dom';

interface Page {
  name: string;
  route: string;
}
const pages: Page[] = [
  { name: 'Home', route: '/' },
  { name: 'About Us', route: '/about' },
  { name: 'Worship Services', route: '/services' },
  { name: 'Ministries', route: '/ministries' },
  { name: 'Events', route: '/events' },
  { name: 'Members', route: '/members' },
  { name: 'Contact Us', route: '/contact-us' },
  { name: 'Give', route: '/donate' },
];

function Header() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (route?: string | null) => {
    setAnchorElNav(null);
    if (route) navigate(route);
  };

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: 'white' }}
      className="no-print"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: 'black' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => handleCloseNavMenu(page.route)}
                  href={page.route}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <div className="logo">
              <a href="/">
                <img src={logo} alt="Logo" height={64} />
              </a>
            </div>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <div className="logo">
              <a href="/">
                <img src={logo} alt="Logo" height={64} />
              </a>
            </div>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' },
            }}
          >
            {pages
              .filter((x) => x.name !== 'Give')
              .map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleCloseNavMenu()}
                  href={page.route}
                  sx={{
                    my: 2,
                    color:
                      location.pathname === page.route
                        ? colors.themeRed
                        : 'black',
                    display: 'block',
                  }}
                >
                  {page.name}
                </Button>
              ))}
            <Button
              href="/donate"
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
                backgroundColor: colors.themeRed,
                textAlign: 'center',
              }}
            >
              Give
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
