import { Box } from '@mui/material';
import React from 'react';

const BackgroundLetter = () => {
  return (
    <Box
      sx={{
        padding: 5,
        margin: 5,
        width: {
          lg: '50%',
        },
        border: '1px solid gray',
      }}
    >
      <p>Dear New Mt. Zion Ministry Member,</p>
      <p>
        Welcome to the New Mt. Zion Missionary Baptist Church employment and
        volunteer verification system. Our church has partnered with Protect My
        Ministry, the leading background check firm in the nation, to ensure
        that we have the right people in the right places and the best resources
        to ensure the safety of our children, members, and church assets. As a
        convenience, the firm’s ministry mobilizer is designed to assist the
        church in providing a one-stop verification process
      </p>
      <p>
        <h3 style={{ textAlign: 'center' }}>
          BACKGROUND CHECK VERIFICATION PROCESS
        </h3>
      </p>
      <p>
        <b>
          All New Mt. Zion leaders, employees, and volunteers must complete the
          electronic background verification process.{' '}
        </b>
        This process will be completed every three years. To begin compliance,
        please click the link below to complete the New Mt. Zion Background
        Check form and the New Mt. Zion Child Protection policy
      </p>
      <p>
        <a href="https://ministryopportunities.org/NMZLakeland">
          https://ministryopportunities.org/NMZLakeland
        </a>
      </p>
      <p>
        <h3 style={{ textAlign: 'center' }}>
          ADDITIONAL TRAINING FOR CHILDREN’S MINISTRY VOLUNTEERS
        </h3>
      </p>
      <p>
        In addition to the background check verification process, all Children
        {"'"}s Ministry volunteers must complete the Child Safety Training
        Course before working in this ministry. This is non-negotiable. The{' '}
        <b>Child Safety Training Course</b> is completed online and consists of
        5 lessons covering the following topics:
      </p>
      <p>
        <ol type="a">
          <li>Lesson 1: Defining Child Sexual Abuse</li>
          <li>Lesson 2: Victim Behavioral Characteristics</li>
          <li>Lesson 3: Characteristics of Child Molesters</li>
          <li>Lesson 4: Making a Difference</li>
          <li>Lesson 5: Responsibility & Reporting</li>
        </ol>
      </p>
      <p>
        The Children’s Church Child Safety Training Modules will be sent to the
        Children’s ministry volunteers after the background check process is
        completed.
      </p>
      <p>
        Please contact the church office with questions. Thank you in advance
        for your interest in serving at New Mt. Zion.
      </p>
      <p>Blessings,</p>
      <p>New Mt. Zion Administration</p>
    </Box>
  );
};

export default BackgroundLetter;
