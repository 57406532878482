import React from 'react';
import { Box } from '@mui/system';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';

const galleries = [
  {
    thumbnail: require(`../../images/deliza.jpg`),
    link: 'https://flashphotos.smugmug.com/Events/Deliza-Ellizee/',
    title: 'Deliza Sendoff July 23, 2023',
  },
  {
    thumbnail: require(`../../images/prostate_2023.JPG`),
    link: 'https://photos.app.goo.gl/CDCuGtscnL45KdL79',
    title: 'Prostrate Cancer Conference 2023',
  },
  {
    thumbnail: require(`../../images/red_2023.jpeg`),
    link: 'https://photos.app.goo.gl/5bw5TgQ84LuWRkXB7',
    title: 'Wear Red Sunday 2023',
  },
];
const Gallery = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {galleries.map(({ thumbnail, link, title }) => (
        <Card
          key={link}
          sx={{
            margin: 1,
            height: 'auto',
            width: '300px',
          }}
        >
          <Link to={link} target="_blank">
            <CardMedia
              component="img"
              className="clickable-card-image"
              width={'300'}
              // width="250"
              image={thumbnail}
            />
            <CardContent>
              <Box>{title}</Box>
            </CardContent>
          </Link>
        </Card>
      ))}
    </Box>
  );
};

export default Gallery;
