import React, { useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material';
import Cropper, { Area } from 'react-easy-crop';
import { getCroppedImg } from './canvasUtils';
import { Person } from '@mui/icons-material';
import { v4 } from 'uuid';

const ProfilePicUploader = ({
  profilePic = null,
  onProfilePhotoUpdated,
}: {
  profilePic?: string | null;
  onProfilePhotoUpdated: (url: string | null) => void;
}) => {
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  //const [croppedImage, setCroppedImage] = useState<string | null>(profilePic);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRemovePhoto = () => {
    setShowConfirmation(true);
  };

  const handleConfirmRemovePhoto = () => {
    setImageSrc(null);
    onProfilePhotoUpdated(null);
    setShowConfirmation(false);
    onProfilePhotoUpdated(null);
  };

  const handleCancelRemovePhoto = () => {
    setShowConfirmation(false);
  };
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const [isCropping, setIsCropping] = useState<boolean>(false);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      setImageSrc(URL.createObjectURL(img));
      setIsCropping(true);
      setZoom(1);
      e.target.value = '';
    }
  };

  //   const handleRemoveImage = () => {
  //     if (confirm('Remove Profile Picture')) {
  //       setImageSrc(null);
  //       setCroppedImage(null);
  //     }
  //   };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      onProfilePhotoUpdated(croppedImage);
      setIsCropping(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation, onProfilePhotoUpdated]);

  const inputid = v4();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '240px' }}>
      {profilePic ? (
        <>
          <img
            src={profilePic}
            alt={`Profile`}
            height="240px"
            width="240px"
            style={{ background: '#eee' }}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              background: '#eee',
              width: '200px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Person sx={{ fontSize: '10rem', color: 'gray' }} />
          </Box>
        </>
      )}

      <label htmlFor={inputid}>
        <Button
          variant="outlined"
          component="span"
          sx={{ width: '100%', mt: 1 }}
        >
          {profilePic ? 'Change' : 'Set'} Profile Photo
        </Button>
      </label>
      {!isCropping && profilePic && (
        <Button
          variant="outlined"
          component="span"
          sx={{ width: '100%', mt: 1 }}
          color="error"
          onClick={handleRemovePhoto}
        >
          Delete Profile Photo
        </Button>
      )}
      <input
        id={inputid}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />

      <Dialog open={isCropping}>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
          {imageSrc && (
            <Box
              sx={{
                height: '400px',
                width: { md: '400px', xs: '300px' },
                background: 'white',
                position: 'relative',
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex' }}>
            <Typography variant="overline" sx={{ mr: 2 }}>
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={10}
              step={0.1}
              aria-labelledby="Zoom"
              //classes={{ root: classes.slider }}
              onChange={(e, x) => setZoom(x as number)}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={() => {
                setIsCropping(false);
                setImageSrc(null);
                setZoom(1);
              }}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={showCroppedImage}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog open={showConfirmation} onClose={handleCancelRemovePhoto}>
        <DialogTitle>Remove Profile Photo</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove your profile photo?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmRemovePhoto} color="primary">
            Yes, Remove
          </Button>
          <Button onClick={handleCancelRemovePhoto} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePicUploader;
