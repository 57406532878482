/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import { ref, getDownloadURL } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { storage } from '../../App';

const Media = () => {
  const { media } = useParams();
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (!media) return;
    const fileRef = ref(
      storage,
      `${media.endsWith('.webm') ? 'video' : 'audio'}/${media}`
    );
    setBusy(true);
    getDownloadURL(fileRef)
      .then((x) => {
        window.location.href = x;
      })
      .catch(() => alert('notfound'))
      .finally(() => {
        setBusy(false);
      });
  }, [media]);
  if (busy) return <CircularProgress />;
  return null;
};

export default Media;
