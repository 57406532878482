import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  CircularProgress,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../App';
import { UserType } from '../Members/Info';
import ImageLoader from '../custom/ImageLoader';

interface Zaga extends UserType {
  id: string;
  email: string;
  familyId?: string;
  parentId?: string;
}

function getMonthName(monthNumber: number) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[monthNumber - 1];
}

function getDaySuffix(dayNumber: number) {
  if (dayNumber >= 11 && dayNumber <= 13) {
    return 'th';
  }
  switch (dayNumber % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

// Mock data for the contacts
// const contacts = [
//   { id: 1, name: 'John Doe', email: 'john@example.com', phone: '123-456-7890' },
//   {
//     id: 2,
//     name: 'Jane Smith',
//     email: 'jane@example.com',
//     phone: '987-654-3210',
//   },
//   //... add more contacts as needed
// ];

function ContactComponent() {
  const [filter, setFilter] = useState('');
  const [membership, setMembership] = useState('all');
  const [busy, setBusy] = useState(false);
  const [contacts, setContacts] = useState<Zaga[]>([]);
  const [selectedContact, setSelectedContact] = useState<Zaga | null>(null);
  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth());
  // Filter contacts based on the search filter
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.firstName.toLowerCase().includes(filter.toLowerCase()) ||
      contact.lastName.toLowerCase().includes(filter.toLowerCase())
  );
  const removeDupes = (conts: Zaga[]) => {
    const seen = new Map(); // Using Map to store unique keys along with objects
    conts.forEach((obj) => {
      const key = obj.firstName.toLowerCase() + obj.lastName;
      // Check if the key exists in the map
      if (!seen.has(key)) {
        // If not, add it to the map
        seen.set(key, obj);
      } else {
        // If the key exists, check if the current object has a profile picture
        if (obj.profilePic) {
          // If it does, replace the existing object in the map with the current one
          seen.set(key, obj);
        }
      }
    });
    // Convert map values back to array
    return Array.from(seen.values());
  };

  useEffect(() => {
    const q = query(collection(db, 'users'), orderBy('firstName', 'asc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setBusy(false);
      const z: Zaga[] = [];
      querySnapshot.forEach((doc) => {
        z.push({ ...doc.data(), ...{ id: doc.id } } as Zaga);
      });
      const conts = removeDupes(
        membership === 'all'
          ? z
          : membership === 'active'
          ? z.filter(
              (x) =>
                x.inactive === undefined ||
                x.inactive === null ||
                x.inactive === false
            )
          : z.filter((x) => x.inactive === true)
      );
      if (conts.length === 1 && conts[0].firstName === 'Rukevwe') {
        return;
      }
      setContacts(conts);
      setSelectedContact((prev) => {
        let index = 0;
        if (prev) {
          index = conts.map((x) => x.id).indexOf(prev.id);
          if (index === -1) {
            index = 0;
          }
        }
        return conts[index];
      });
    });
    return () => {
      setSelectedContact(null);
      unsubscribe();
    };
  }, [membership]);
  if (busy) return <CircularProgress />;

  const family = contacts.filter(
    (x) => x.parentId && selectedContact && x.parentId === selectedContact?.id
  );

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const activateDeactivate = async () => {
    const ref = doc(collection(db, 'users'), selectedContact?.id);
    try {
      await updateDoc(ref, {
        inactive: selectedContact?.inactive ? false : true,
      });
    } catch (e) {
      console.log({ e });
      alert('unable to save');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Typography fontWeight="bold">Members ({contacts.length})</Typography>
          <Select
            labelId="active-label"
            value={membership}
            onChange={(x) => setMembership(x.target.value)}
            label="Membership"
            sx={{ ml: 2 }}
          >
            <MenuItem key={'all'} value={'all'}>
              All
            </MenuItem>
            <MenuItem key={'active'} value={'active'}>
              Active
            </MenuItem>
            <MenuItem key={'inactive'} value={'inactive'}>
              In-active
            </MenuItem>
          </Select>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          label="Search Members"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <List
          component="nav"
          aria-label="contacts"
          sx={{
            height: '300px',
            overflowY: 'scroll',
            border: '2px solid gray',
          }}
        >
          {filteredContacts.map((contact) => (
            <ListItem
              button
              key={contact.id}
              onClick={() => setSelectedContact(contact)}
              style={{
                fontStyle: contact.inactive ? 'italic' : 'normal',
                color: contact.inactive ? 'gray' : 'black',
                backgroundColor:
                  selectedContact && selectedContact.id === contact.id
                    ? '#e0e0e0'
                    : 'transparent',
              }}

              //selected={selectedContact && selectedContact.id === contact.id}
            >
              <ListItemText
                primary={`${contact?.firstName} ${contact?.lastName}${
                  contact.inactive ? ' (In-active)' : ''
                }`}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box p={2}>
          {selectedContact ? (
            <Container
              sx={{
                fontStyle: selectedContact.inactive ? 'italic' : 'normal',
              }}
            >
              <Typography variant="h5" gutterBottom>
                {selectedContact.firstName} {selectedContact.lastName}
                {selectedContact.inactive ? '  (In-active)' : ''}
              </Typography>
              {selectedContact.profilePic && (
                <ImageLoader
                  src={selectedContact.profilePic}
                  alt="profile-pic"
                  width={150}
                />
              )}
              <Button
                variant="outlined"
                sx={{ mt: 1, mb: 1 }}
                onClick={activateDeactivate}
              >
                {selectedContact.inactive ? 'Activate' : 'De-activate'}
              </Button>

              <Typography>Email: {selectedContact.email}</Typography>
              <Typography>Phone: {selectedContact.phoneNumber}</Typography>
              <Typography>
                {' '}
                Birthday: {getMonthName(selectedContact.dobMonth)}{' '}
                {selectedContact.dobDay}
                {getDaySuffix(selectedContact.dobDay)}
              </Typography>
              <Typography>
                Address:{' '}
                {!selectedContact?.addressLine1
                  ? 'N/A'
                  : `${selectedContact.addressLine1}, ${selectedContact.addressCity}, ${selectedContact.addressState}, ${selectedContact.addressZip}`}
              </Typography>
              {selectedContact.parentId ? (
                <Button
                  onClick={() =>
                    setSelectedContact(
                      contacts.find((x) => x.id === selectedContact.parentId) ??
                        null
                    )
                  }
                >
                  Parent:{' '}
                  {
                    contacts.find((x) => x.id === selectedContact.parentId)
                      ?.firstName
                  }{' '}
                  {
                    contacts.find((x) => x.id === selectedContact.parentId)
                      ?.lastName
                  }
                </Button>
              ) : (
                <></>
              )}
              {family.length > 0 && (
                <>
                  <Typography fontWeight="bold" sx={{ mt: 2 }}>
                    Family members
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    {family.map((f) => (
                      <Button
                        key={f.id}
                        onClick={() =>
                          setSelectedContact(
                            contacts.find((x) => x.id === f.id) ?? null
                          )
                        }
                      >
                        {f.firstName} {f.lastName}
                      </Button>
                    ))}
                  </Box>
                </>
              )}
            </Container>
          ) : (
            <Typography variant="h6">
              Select a contact to view details
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Typography fontWeight="bold" sx={{ mr: 5 }}>
            Birthdays
          </Typography>
          <FormControl variant="outlined">
            <InputLabel id="month-label">Month</InputLabel>
            <Select
              labelId="month-label"
              value={month}
              onChange={(x) => setMonth(x.target.value as number)}
              label="Month"
            >
              {monthNames.map((m) => (
                <MenuItem key={m} value={monthNames.indexOf(m)}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      {contacts
        .filter((x) => x.includeInBirthdayEmails && x.dobMonth === month + 1)
        .map((user) => (
          <Grid
            item
            xs={6}
            md={6}
            key={user.id}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Box
              sx={{
                m: 2,
                //background: 'red',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ImageLoader
                alt={user.firstName}
                src={user.profilePic ?? ''}
                height={200}
              />
              <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                {user.firstName} {user.lastName}
              </Typography>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
}

export default ContactComponent;
