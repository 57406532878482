import { Card, CardContent, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface Leader {
  name: string;
  pic: string;
  position: string;
}
const leaders: Leader[] = [
  {
    name: 'Edward L. Quary Jr.',
    position: 'Pastor',
    pic: 'pastor1.jpg',
  },
  {
    name: 'Beverly Boatwright',
    position: 'Church Secretary',
    pic: 'BEVERLY-BOATWRIGHT-min-scaled.jpg',
  },
  {
    name: 'Barbara Harrison',
    position: 'Health Ministry Leader',
    pic: 'BARBARA-HARRISON-min-scaled.jpg',
  },
  {
    name: 'Natalie Montgomery',
    position: 'Youth Ministry Director',
    pic: 'NATALIE-MONTGOMERY-min-scaled.jpg',
  },
];
const Leaders = () => {
  return (
    <Box>
      <div style={{ margin: '0rem', lineHeight: '2rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            flexFlow: 'wrap',
            //padding: '2rem',
            justifyContent: 'center',
          }}
        >
          {leaders.map((l) => (
            <Card
              key={l.name}
              sx={{ margin: 2, width: { md: '40%', sx: '100%' } }}
            >
              <CardMedia
                component="img"
                //width="250"
                image={require(`../../images/leaders/${l.pic}`)}
              />
              {/* </Link> */}
              <CardContent>
                <Box
                  sx={{
                    //margin: 1,
                    fontSize: { md: '1.3rem', xs: '1rem' },
                  }}
                >
                  <h3>{l.name}</h3>
                  <div>{l.position}</div>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
    </Box>
  );
};

export default Leaders;
