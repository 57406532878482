// src/components/About.tsx

import Button from '@mui/material/Button';
import { FavoriteBorder } from '@mui/icons-material';

import React from 'react';
import { colors } from '../utils';
import { Box } from '@mui/material';
// import { colors } from '../utils';

const DonateBanner = () => {
  return (
    <div className="donate-banner">
      <div>
        <FavoriteBorder sx={{ fontSize: '4rem', color: colors.themeRed }} />
      </div>
      <Box
        sx={{
          fontSize: {
            md: '2.5rem',
            xs: '1rem',
            fontWeight: 'bold',
            padding: '1rem',
          },
          lineHeight: { md: '4rem', xs: '2rem' },
        }}
      >
        “In everything I did, I showed you that by this kind of hard work we
        must help the weak, remembering the words the Lord Jesus himself said:
        ‘It is more blessed to give than to receive”
      </Box>
      <Box
        sx={{
          fontSize: {
            md: '1.5rem',
            xs: '1rem',
            fontStyle: 'italic',
            fontWeight: '200',
          },
        }}
      >
        Acts 20:35
      </Box>

      <Button
        variant="contained"
        href="/donate"
        color="primary"
        style={{ backgroundColor: 'rgb(233, 32, 79)', margin: '2rem' }}
      >
        GIVE NOW
      </Button>
    </div>
  );
};

export default DonateBanner;
