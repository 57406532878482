// src/components/About.tsx

import {
  Church,
  Diversity3,
  EscalatorWarning,
  MenuBook as Note,
} from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import React from 'react';

const Services = () => {
  return (
    <div className="services">
      <div className="services-hero">Worship Services</div>
      <div className="services-tag">
        Worshipping the Lord is at the core of everything we do. Are you ready
        to connect with us? <br /> Whether you log in or stop in, we’ll be happy
        to have you join us. See you soon!
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexFlow: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ padding: 2, margin: 1 }}>
          <CardContent className="services-card">
            <Church fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Sunday Morning Worship</div>
            <div>Sundays</div>
            <div>Time: 11:00 AM</div>
            <div>In Person + Virtually</div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1 }}>
          <CardContent className="services-card">
            <Note fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Bible Study</div>
            <div>Wednesdays</div>
            <div>Time: 6:30pm</div>
            <div>In Person + Virtually</div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1 }}>
          <CardContent className="services-card">
            <Diversity3 fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Sunday School</div>
            <div>Sundays</div>
            <div>Time: 9:45am - 10:45am</div>
            <div>Dial-in number: (929) 266-1815 </div>
            <div>PIN: 581 780 617#</div>
            <div>
              Join from Computer: <br />
              <a href="https://meet.google.com/qdd-zkrs-zht">
                https://meet.google.com/qdd-zkrs-zht
              </a>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1 }}>
          <CardContent className="services-card">
            <EscalatorWarning fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Children’s Church</div>
            <div>Sundays</div>
            <div>Time: 11:00 AM</div>
            <div>In Person</div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Services;
