import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';

export interface IBreastFormData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}

const Bolden = ({ form = null }: { form?: DocumentData | null }) => {
  console.log(form);
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container
        sx={{
          width: {
            md: '50%',
            xs: '100%',
          },
        }}
      >
        <Typography variant="h3">D. M. Bolden Scholarship Award </Typography>
        <p>
          It is graduation time again and the D. M. Bolden Scholarship Award
          Application is attached and can be found on the NMZ website; it is
          open to NMZ high school graduating seniors.{' '}
        </p>
        <p>
          Additionally, any NMZ students who are graduating from high school or
          college are asked to submit their names and contact information to the
          office by May 19.
        </p>
        <p>
          Please get busy seniors and submit your documentation timely. Forms
          may be hand delivered, mailed to 1321 N. Webster Ave., Lakeland 33805
          or emailed to{' '}
          <a href="mailto:info@nmzlakeland.org.">info@nmzlakeland.org.</a>{' '}
        </p>

        <Button
          variant="contained"
          href={require(`../../images/DMB-Scholarship-revised.docx`)}
          target="_blank"
        >
          Download Form
        </Button>
      </Container>
    </Box>
  );
};

export default Bolden;
