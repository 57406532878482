import React, { useState } from 'react';

function ImageLoader({
  src: imageUrl,
  height,
  width,
  alt,
}: {
  src: string;
  alt: string;
  width?: number | undefined;
  height?: number | undefined;
}) {
  const [isValid, setIsValid] = useState<boolean | null>(null);

  // When component mounts or imageUrl changes
  React.useEffect(() => {
    // Reset state
    setIsValid(null);

    // Create a new Image object
    const img = new Image();

    // Success callback
    img.onload = () => {
      setIsValid(true);
    };

    // Error callback
    img.onerror = () => {
      setIsValid(false);
    };

    // Assign the URL to trigger the loading process
    img.src = imageUrl;

    // Cleanup listeners if component is unmounted
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  console.log({ imageUrl, isValid });
  // Render based on isValid state
  return (
    <div>
      <div>{isValid}</div>
      {isValid === true && (
        <img src={imageUrl} alt={alt} height={height} width={width} />
      )}
      {isValid === false && (
        <img
          src={require('../../images/blank_avatar.webp')}
          alt={alt}
          height={height}
          width={width}
        />
      )}
    </div>
  );
}

export default ImageLoader;
