import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import React from 'react';
import { colors } from '../../utils';
import ChurchHistory from './ChurchHistory';
import { useParams, useNavigate } from 'react-router-dom';
import Leaders from './Leaders';

const Church = () => {
  return (
    <Box>
      <div style={{ margin: '0rem', lineHeight: '2rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            //padding: '2rem',
          }}
        >
          <div style={{ margin: '0rem', flex: 1 }}>
            <h2>A Story of Faith</h2>

            <p>
              What started as a dream in 1929, has matured into the incredible
              church community that you see today. At NMZ Lakeland, we give new
              meaning to the term humble beginnings. Our first ever church home
              was just a tent in a parking lot before a building was purchased
              for $50.00 (that’s right, it was $50.00).
            </p>
            <p>
              By 1954, we began to grow and had approximately 80 members. As
              word began to spread and a lineage of great pastors committed
              themselves to our plan, this number ballooned to 350 members in
              the coming years and we were affectionately known as “Holy Ghost
              Headquarters”.
            </p>
          </div>
          <Box sx={{ flex: 1, margin: '1rem' }}>
            <img
              src={require('../../images/nmz_bg.jpg')}
              alt="church"
              width="100%"
              style={{ height: 'auto' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            //padding: '2rem',
          }}
        >
          <Box sx={{ flex: 1, margin: '1rem' }}>
            <img
              src={require('../../images/abouts-768x512.jpg')}
              alt="church"
              width="100%"
              style={{ margin: '1rem' }}
            />
          </Box>
          <div style={{ margin: '1rem', flex: 1 }}>
            <p>
              It was just over a decade later in 1968 when our church opened at
              its present site and since then we have blossomed to unimaginable
              heights.
            </p>
            <p>
              We are proud to say that we have invested in the church year after
              year with both our finances and our dedication and commitment to
              the Lord. It has taken an army of believers to make this happen
              each and every step of the way.
            </p>
            <p>
              We have grown from a tent to one of the most technologically
              advanced worship centers in the state. NMZ Lakeland is living
              proof of what can happen when you have the faith of a mustard
              seed.
            </p>
          </div>
        </Box>
        <Box sx={{ margin: '1rem' }}>
          <h2>Trust in Our Leadership</h2>
          <p>
            There’s no way we would be where we are today without placing all of
            our trust in the Lord and Savior Jesus Christ. Under the leadership
            of our brilliant and fearless leader, Pastor Edward L. Quary Jr., we
            are confident that even better days are ahead. Married to his high
            school sweetheart, Rahsheia Thompson Quary, Pastor Quary is a family
            man and believes that his wife and two children are his first
            ministerial priority.
          </p>
          <p>
            His ministerial objective is to serve the risen savior Jesus Christ
            with his life, through both the practice and proclamation of his
            Holy Word. He has served as Pastor of the Friendship M.B. Church of
            Dundee, FL and the Truvine M.B. Church of Sarasota, FL and is
            totally committed to the mission of growing Disciples of Jesus
            Christ through the preaching of the infallible, inerrant Word of
            God!
          </p>
          <b>He summarizes this calling with the following words:</b>
          <p>
            “It is my desire to please God as a demonstration of my gratitude
            for his immeasurable grace towards me. My prayer is that Jesus
            Christ may he be glorified in everything we endeavor to accomplish
            for his great name and that Christ may forever receive the highest
            honor, glory, and praise.”
          </p>
          <p>
            With Pastor Quary at the head of our mission and an amazing staff of
            hardworking believers behind him, NMZ Lakeland is sure to soar to
            new heights in the years to come.
          </p>
          <p>Just trust us.</p>
        </Box>
      </div>
    </Box>
  );
};

const Pastor = () => {
  return (
    <Box sx={{ lineHeight: '2rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box sx={{ width: { md: '25%', xs: '100%' } }}>
          <img
            src={require('../../images/pastor1.jpg')}
            style={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <h2>
            2 Corinthians 4:5 For we preach not ourselves, but Christ Jesus the
            Lord; and ourselves your servant
          </h2>
          <p>
            “I’ll never be able to give this church more than what it gave me”.
          </p>
          <p>
            NMZ Lakeland is truly a community church. It’s a place of worship
            where everyone feels valued, loved, and respected. There’s
            absolutely no doubt that this progressive, welcoming culture starts
            and ends with its fearless leader, Edward L. Quary Jr. A
            self-proclaimed introvert,{' '}
            <b>
              Pastor Quary admits that he happily breaks out of his shell
              exclusively for two things; spreading the word of God and his
              passion for helping people.
            </b>{' '}
            It is this loving foundation that is helping him build a church
            that’s rooted in the principles of sharing and caring. With these
            building blocks, NMZ Lakeland is evolving into something special.
          </p>
        </Box>
      </Box>
      <p>
        Just ask anyone who knows him. They will likely tell you that Pastor
        Quary has a strong presence that’s hard to ignore. While he appears to
        be calm and understated, he lights up every room and makes people feel
        safe and protected. His warmth and charm is undeniable and creates an
        environment that empowers people to be who they really are. These are
        just some of the endearing qualities that make him fit to lead a church
        that he has called home for most of his life.
      </p>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column-reverse',
            md: 'row',
          },
          backgroundColor: colors.themeRed,
          color: 'white',
        }}
      >
        <Box sx={{ padding: '1rem', flex: 1 }}>
          <p>
            After being born in Lakeland, FL on Christmas Day, he grew up in the
            local church where he describes the Youth Department at the time as
            “active and vibrant”. As a “product” of the church system he now
            leads, he is rooted in the church community that has undergone lots
            of growing pains over the years.
          </p>
        </Box>
        <Box sx={{ flex: 1, padding: '0rem' }}>
          <img
            src={require('../../images/pastor2.png')}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
      <p>
        In fact, some of the more recent struggles came during the COVID-19
        pandemic. At the time, he was excelling at another church in nearby
        Sarasota, FL. However, being so intertwined in the Lakeland community,
        he knew that the church where he first served was in dire need of a new
        pastor. Unable to ignore his true calling, he ended up applying for the
        position and coming back home to lead NMZ Lakeland in May of 2020.
      </p>
      <Box sx={{ flex: 1, padding: '0rem' }}>
        <img
          src={require('../../images/pastor3.png')}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <p>
        While pastoring a church in the heart of a pandemic wasn’t easy, the
        spirit of the community felt during these times reassured him that he
        made the right decision. The summer of 2020 saw a series of outdoor
        church sessions with people praising the Lord as they sat in lawn chairs
        in their front yards. They celebrated the teachings from the Bible while
        standing the mandatory 6-feet apart, searching for shade under decade
        old trees. Curious members of the community listened carefully from the
        street corners and nearby basketball courts – searching for some hope
        for better days with each word from Pastor Quary. In a time of immense
        uncertainty and sickness, the community banded together around the
        church. This was the Lord’s doing. When everything else seemed to be
        falling apart, it was the church that got stronger.
      </p>
      <p>
        While Pastor Quary is always complimentary of the regime that came
        before him, he is focused on making NMZ Lakeland better than ever. One
        of his favorite quotes reads;{' '}
        <b>
          “We cannot love the church of the past more than we do the church of
          the future”
        </b>
        . This provides the perfect segue to his vision for the near future.
        When asked about the church’s 3-5-year plan, he stresses the importance
        of creating a tech savvy ministry for the next generation.
      </p>
      <p>
        “Even though we have a rich past, no one is being saved in the past.
        But, people will be saved in the future. We want to create a vibrant
        future where there are disciples who are making disciples at every age,”
        he said.
      </p>
      <p>
        Pastor Quary believes that nothing is more important than passing on the
        importance of worship to the next generation and he believes that this
        can’t be done without embracing the concept of shared leadership. As
        Lakeland continues to grow at rapid rates, he knows that the influx of
        new residents will lead to more members in the church as well. It’s his
        vision that people will accept their calling to serve and be a part of
        the church.
      </p>
      <p>
        Thankfully, he won’t have to do any of the Lord’s work alone. He is
        married to his high school sweetheart, Rahsheia Thompson Quary and to
        this union God has added two beautiful children. As a family man, he
        believes that his wife and children are his first ministerial priority.
        With his family and a great church community behind him, look for NMZ
        Lakeland to continue to do great things.
      </p>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const pages = ['pastor', 'history', 'leaders'];
const About = () => {
  const { page } = useParams();
  const nav = useNavigate();

  const value = page ? pages.indexOf(page) + 1 : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    nav(`/about/${newValue === 0 ? '' : pages[newValue - 1]}`);
  };
  return (
    <Box component={'span'}>
      <div className="contactus-hero">About Us</div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            scrollButtons="auto"
            variant="fullWidth"
          >
            <Tab label="Our Church" {...a11yProps(0)} />
            <Tab label="Our Pastor" {...a11yProps(1)} />
            <Tab label="Our History" {...a11yProps(2)} />
            <Tab label="Our Leaders" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Church />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Pastor />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ChurchHistory />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Leaders />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default About;
