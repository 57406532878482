import React, { useEffect, useState } from 'react';
import { FormIds, getCompletedForm } from '../../services';
import { useParams } from 'react-router-dom';
import VolunteerForm from './VolunteerForm';
import SchoolBash from './SchoolBash';
import { CircularProgress } from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';
import ContactForm from './ContactForm';
import LoginPage from '../Members/LoginPage';
import { User } from 'firebase/auth';
import BudgetRequestForm from './BudgetRequestForm';
import BreastCancer from './BreastCancer';
import SkateWorldPermissionForm from './SkateWorldPermissionForm';
import MentalHealth from './MentalHealth';

const CompletedForm = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<DocumentData>();
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    if (id && user) {
      setIsLoading(true);
      getCompletedForm(id)
        .then((f) => {
          console.log({ f });

          setIsLoading(false);
          if (f) {
            setForm(f);
          }
        })
        .catch(() => {
          setIsLoading(false);

          alert('Unable to Load');
        });
    }
  }, [id, user]);
  if (isLoading) return <CircularProgress size={20} />;

  if (!user) return <LoginPage onUserSet={setUser} />;
  console.log(form);
  if (!form) return <div style={{}}></div>;
  if (form?.formId === FormIds.contact) return <ContactForm form={form} />;
  if (form?.formId === FormIds.volunteer) return <VolunteerForm form={form} />;
  if (form?.formId === FormIds.budgetForm2024)
    return <BudgetRequestForm form={form} />;
  if (form?.formId === FormIds.budgetForm2025)
    return <BudgetRequestForm form={form} />;
  if (form?.formId === FormIds.schoolBash2024)
    return <SchoolBash form={form} />;
  if (form?.formId === FormIds.breastCancerSeminar2024)
    return <BreastCancer form={form} />;
  if (form?.formId === FormIds.skateworldSpringBreak)
    return <SkateWorldPermissionForm form={form} />;
  if (form?.formId === FormIds.mentalHealth2024)
    return <MentalHealth form={form} />;
  return <div></div>;
};

export default CompletedForm;
