import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type StyledTextFieldProps = TextFieldProps & { isFilled?: boolean };

export const FormTextField = ({ isFilled, ...rest }: StyledTextFieldProps) =>
  isFilled ? (
    <TextField
      {...rest}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#000000',
        },
      }}
    />
  ) : (
    <TextField {...rest} />
  );
