import React, { useCallback, useEffect, useState } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  User,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, Container } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db, storage } from '../../App';
import { getDownloadURL, ref } from 'firebase/storage';

const LoginPage = ({
  onUserSet,
}: {
  onUserSet: (user: User | null | undefined) => void;
}) => {
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const [busy, setBusy] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openLogout, setOpenLogout] = useState(false); // State to control the Logout Dialog

  const handleClickOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const handleConfirmLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('You have been signed out.');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarSeverity('error');
        setSnackbarMessage(`Error signing out`);
        setSnackbarOpen(true);
      });
    setOpenLogout(false);
  };

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
  };

  const handleClickOpenSignIn = () => {
    setOpenSignIn(true);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const handleCloseSignIn = () => {
    setOpenSignIn(false);
  };

  const handleSnackbarClose = (reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const getImageURL = useCallback(async () => {
    if (firstName && lastName) {
      try {
        const name = `profile-pics/${firstName.trim().toUpperCase()}-${lastName
          .trim()
          .toUpperCase()}.jpg`;
        const fileRef = ref(storage, name);

        // Get the download URL for the image
        const imageUrl = await getDownloadURL(fileRef);
        return imageUrl; // You can use this URL to display the image in your web app
      } catch (error) {
        return null;
      }
    }
  }, [firstName, lastName]);

  const handleSignUp = async () => {
    setBusy(true);
    const profilePic = await getImageURL();
    createUserWithEmailAndPassword(auth, email, password)
      .then((u) => {
        const usersRef = collection(db, 'users');
        const userRef = doc(usersRef, u.user.uid);
        const userId = userRef.id; // a docRef has an id property
        const userData = {
          userId,
          receiveCommunication: true,
          profilePic,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          firstName_lc: firstName.trim().toLowerCase(),
          lastName_lc: lastName.trim().toLowerCase(),
          email,
          createdOn: new Date(),
        }; // insert the id among the data
        return setDoc(userRef, userData);
      })
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('Sign up successful!');
        setSnackbarOpen(true);
        setOpenSignUp(false);
        // Navigate to another page...
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.message.includes('already-in-use'))
          errorMessage = 'This email address is already in use';
        if (error.message.includes('weak-password'))
          errorMessage = 'Password is took weak. Use at least 6 characters';
        setSnackbarSeverity('error');
        setSnackbarMessage(`Error signing up: ${errorMessage}`);
        setSnackbarOpen(true);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handleSignIn = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('Sign in successful!');
        setSnackbarOpen(true);
        setOpenSignIn(false);
        // Navigate to another page...
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.message.includes('wrong-password'))
          errorMessage = 'Invalid password';
        setSnackbarSeverity('error');
        setSnackbarMessage(`Error signing in: ${errorMessage}`);
        setSnackbarOpen(true);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handlePasswordReset = (email: string) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage(`Password reset email sent to ${email}`);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        setSnackbarSeverity('error');
        setSnackbarMessage(
          `Error sending password reset email: ${error.message}`
        );
        setSnackbarOpen(true);
      });
  };
  useEffect(() => {
    onUserSet(user);
  }, [onUserSet, user]);
  return (
    <Container sx={{ minHeight: '400px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '1rem',
        }}
      >
        {!user && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpenSignUp}
              sx={{ mr: 1 }}
            >
              Sign Up
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpenSignIn}
              sx={{ ml: 1 }}
            >
              Sign In
            </Button>
          </>
        )}
        {user && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              flex: 1,
            }}
          >
            {user?.email && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  if (confirm('Reset Password?'))
                    handlePasswordReset(user.email ?? '');
                }}
                sx={{ mr: 2 }}
              >
                Change Password
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpenLogout}
            >
              Logout
            </Button>
          </Box>
        )}
      </Box>
      <Dialog open={openSignUp} onClose={handleCloseSignUp}>
        <DialogTitle>Sign Up for a Profile Account</DialogTitle>
        <DialogContent>
          <form>
            <DialogContentText>
              To sign up, please enter your email address and password here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSignUp();
                }
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignUp}>Cancel</Button>
          <Button onClick={handleSignUp} disabled={busy}>
            Sign Up
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSignIn} onClose={handleCloseSignIn}>
        <DialogTitle>Sign In to your Profile Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To sign in, please enter your email address and password here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSignIn();
              }
            }}
          />
          <Button onClick={() => handlePasswordReset(email)} color="primary">
            Forgot password?
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignIn}>Cancel</Button>
          <Button onClick={handleSignIn} disabled={busy}>
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(_, x) => handleSnackbarClose(x)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleSnackbarClose('')}
          severity={snackbarSeverity === 'success' ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Logout Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LoginPage;
