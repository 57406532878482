import React from 'react';
import { Box } from '@mui/system';
import { FormIds } from '../../services';

const Forms = () => {
  return (
    <Box component={'span'}>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          padding: '1rem',
          lineHeight: '2rem',
        }}
      >
        <ul>
          <li>
            <a href={`/forms/${FormIds.budgetForm2025}`}>
              Budget Request Form (2025)
            </a>
          </li>
          <li>
            <a href={`/forms/${FormIds.breastCancerSeminar2024}`}>
              Breast Cancer Registration Form (2024)
            </a>
          </li>
          <li>
            <a href={`/forms/${FormIds.bolden2024}`}>
              D. M. Bolden Scholarship Award Application
            </a>
          </li>
          {/* <li>
            <a href={`/forms/${FormIds.skateworldSpringBreak}`}>
              Skate World Skate Night Permission Form
            </a>
          </li> */}
          {/* <li>
            <a href={`/forms/${FormIds.breastCancerSeminar2024}`}>
              Breast Cancer Registration Form (2023)
            </a>
          </li> */}
          {/* <li>
            <a href={`/forms/${FormIds.budgetForm2024}`}>
              Budget Request Form (2024)
            </a>
          </li> */}
          {/* <li>
            <a href={`/budget-planning-letter-2024`}>
              2024 Budget Planning Letter
            </a>
          </li> */}
          <li>
            <a href={`/forms/${FormIds.volunteer}`}>
              Employment and Volunteer Application Form
            </a>
          </li>
          <li>
            <a href={`/background-checks`}>
              NMZ Background Verification and Training Letter
            </a>
          </li>
          <li>
            <a href={`/forms/${FormIds.schoolBash2024}`}>
              Back to School Bash 2024
            </a>
          </li>
        </ul>
      </Box>{' '}
    </Box>
  );
};

export default Forms;
