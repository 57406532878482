/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  IconButton,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { User } from 'firebase/auth';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  onSnapshot,
  doc,
  collection,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage } from '../../App';
import { RemoveCircle } from '@mui/icons-material';
import { FormTextField } from '../custom/CustomComponents';
import { v4 as uuidv4, v4 } from 'uuid';
import ProfilePicUploader from './ProfilePicUploader';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const validatePhoneNumber = (number: string) => {
  const pattern = /^\d{10}$/;
  return pattern.test(number);
};
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

interface Family {
  firstName: string;
  lastName: string;
  dobDay?: number;
  dobMonth?: number;
  profilePic?: string | null;
  id: string;
  familyId?: string | null;
}

export interface UserType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneError: boolean;
  receiveCommunication: boolean;
  includeInBirthdayEmails?: boolean;
  dobDay: number;
  dobMonth: number;
  family: Family[];
  profilePic?: string | null;
  familyId?: string | null;
  addressLine1?: string | null;
  addressCity?: string | null;
  addressZip?: string | null;
  addressState?: string | null;
  inactive?: boolean;
}

const ChildInputs = ({
  index,
  childState,
  onChange,
  handleDeleteChild,
}: {
  index: number;
  childState: Family;
  onChange: (f: Family) => void;
  handleDeleteChild: (index: number) => void;
}) => {
  const [res, setRes] = useState<{ [key: string]: string }>({});
  const onBlur = async () => {
    const key = `${childState.firstName
      .toLowerCase()
      .trim()}${childState.lastName.toLowerCase().trim()}`;
    if (res[key] || childState.profilePic) {
      //onChange({ ...childState, ...{ profilePic: res[key] } });

      return;
    }
    if (childState.firstName && childState.lastName) {
      try {
        const name = `profile-pics/${childState.firstName
          .trim()
          .toUpperCase()}-${childState.lastName.trim().toUpperCase()}.jpg`;
        const fileRef = ref(storage, name);

        // Get the download URL for the image
        const imageUrl = await getDownloadURL(fileRef);
        console.log(imageUrl);
        onChange({ ...childState, ...{ profilePic: imageUrl } });
        setRes((p) => ({ ...p, ...{ [key]: imageUrl } }));
      } catch (error) {
        return null;
      }
    }
  };
  return (
    <>
      <Grid item md={5} xs={12}>
        <FormTextField
          label={`First Name`}
          name={`firstName${index}`}
          onChange={(x) =>
            onChange({ ...childState, ...{ firstName: x.target.value } })
          }
          variant="outlined"
          value={childState.firstName}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <FormTextField
          label={`Last Name`}
          name={`lastName${index}`}
          onChange={(x) =>
            onChange({ ...childState, ...{ lastName: x.target.value } })
          }
          variant="outlined"
          value={childState.lastName}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item md={2} xs={12}></Grid>
      <Grid item xs={6} md={5}>
        <FormControl fullWidth>
          <InputLabel id="day-select-label">Birth Day (Optional)</InputLabel>
          <Select
            labelId="day-select-label"
            id="day-select"
            value={childState.dobDay}
            onChange={(x) =>
              onChange({
                ...childState,
                ...{ dobDay: x.target.value as number },
              })
            }
            label="Birth Day (Optional)"
          >
            {[...Array(31)]
              .map((_, i) => i + 1)
              .map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6} md={5}>
        <FormControl fullWidth>
          <InputLabel id="month-select-label">
            Birth Month (Optional)
          </InputLabel>
          <Select
            labelId="month-select-label"
            id="dobMonth"
            value={childState.dobMonth}
            onChange={(x) =>
              onChange({
                ...childState,
                ...{ dobMonth: x.target.value as number },
              })
            }
            label="Birth Month (Optional)"
            sx={{ mr: 1 }}
          >
            {months.map((month, index) => (
              <MenuItem key={month} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={2}>
        {' '}
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteChild(index)}
        >
          <RemoveCircle />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <ProfilePicUploader
          key={childState.id}
          profilePic={childState.profilePic}
          onProfilePhotoUpdated={(x) => {
            return onChange({ ...childState, ...{ profilePic: x } });
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </Grid>
    </>
  );
};

const Info = ({ user }: { user: User }) => {
  const [busy, setBusy] = useState(false);
  const [saving, setSaving] = useState(false);

  const [initialState, setInitiateState] = useState<UserType>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneError: false,
    receiveCommunication: true,
    dobDay: 1,
    dobMonth: 1,
    family: [],
  });
  const [hasUpdated, setHasUpdated] = useState(false);
  const [formState, setFormState] = useState<UserType>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneError: false,
    receiveCommunication: true,
    dobDay: 1,
    dobMonth: 1,
    family: [],
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validatePhoneNumber(formState.phoneNumber)) {
      setFormState({ ...formState, phoneError: true });
      return;
    }
    setFormState({ ...formState, phoneError: false });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    if (user?.uid) {
      setBusy(true);
      const unsub = onSnapshot(doc(db, 'users', user.uid), (doc) => {
        if (doc.exists()) {
          const d = doc.data();
          const newState: UserType = {
            firstName: d.firstName ?? '',
            lastName: d.lastName ?? '',
            receiveCommunication: d.receiveCommunication ?? true,
            includeInBirthdayEmails: d.includeInBirthdayEmails ?? false,
            phoneNumber: d.phoneNumber ?? '',
            dobDay: d.dobDay ?? 1,
            dobMonth: d.dobMonth ?? 1,
            phoneError: false,
            family: d.family ?? [],
            profilePic: d.profilePic ?? null,
            familyId: d.familyId ?? null,
            addressCity: d.addressCity ?? null,
            addressLine1: d.addressLine1 ?? null,
            addressState: d.addressState ?? null,
            addressZip: d.addressZip ?? null,
          };
          setFormState({ ...newState });
          setInitiateState({ ...newState });
          setBusy(false);
        }
      });
      return () => unsub();
    }
  }, [user]);

  const handleDeleteChild = (index: number) => {
    const idToDelete = formState.family[index].id;
    const newChildren = [...formState.family];
    newChildren.splice(index, 1);
    //if (newChildren.length === 0) return;
    setFormState((x) => ({ ...x, ...{ family: newChildren } }));

    const ref = doc(collection(db, 'users'), idToDelete);
    deleteDoc(ref)
      .then(() => {})
      .catch(() => {});
  };
  const addChild = () => {
    const newChildren = [...formState.family];
    newChildren.push({
      firstName: '',
      lastName: '',
      dobDay: 1,
      dobMonth: 1,
      id: uuidv4(),
    });
    setFormState((x) => ({ ...x, ...{ family: newChildren } }));
  };
  useEffect(() => {
    setHasUpdated(JSON.stringify(initialState) !== JSON.stringify(formState));
  }, [formState, initialState]);

  const save = async () => {
    setSaving(true);
    const newform = { ...formState } as any;
    newform.firstName = newform.firstName.trim();
    newform.lastName = newform.lastName.trim();

    if (newform.profilePic && !newform.profilePic.startsWith('http')) {
      const storageRef = ref(storage, `profile-pics/${uuidv4()}.jpg`);
      try {
        const response = await fetch(newform.profilePic);
        const blob = await response.blob();
        const res = await uploadBytes(storageRef, blob, {
          contentType: 'image/jpeg',
        });
        const url = await getDownloadURL(res.ref);
        newform.profilePic = url;
        console.log({ url });
      } catch (e) {
        console.log(e);
      }
    }

    if (newform.family) {
      newform.family = newform.family.filter(
        (x: { firstName: any; lastName: any }) => x.firstName && x.lastName
      );
      if (!newform.familyId) {
        const familyId = v4();

        newform.familyId = familyId;
      }
      for (let i = 0; i < newform.family.length; i++) {
        newform.family[i].parentId = user.uid;
        newform.family[i].includeInBirthdayEmails =
          formState.includeInBirthdayEmails;
        newform.family[i].receiveCommunication = formState.receiveCommunication;
        newform.family[i].familyId = newform.familyId;
        newform.family[i].firstName = newform.family[i].firstName.trim();
        newform.family[i].lastName = newform.family[i].lastName.trim();
        newform.family[i].firstName_lc = newform.family[i].firstName
          .trim()
          .toLowerCase();
        newform.family[i].lastName_lc = newform.family[i].lastName
          .trim()
          .toLowerCase();

        if (
          newform.family[i].profilePic &&
          !newform.family[i].profilePic.startsWith('http')
        ) {
          const storageRef = ref(storage, `profile-pics/${uuidv4()}.jpg`);
          try {
            const response = await fetch(newform.family[i].profilePic);
            const blob = await response.blob();
            const res = await uploadBytes(storageRef, blob, {
              contentType: 'image/jpeg',
            });
            const url = await getDownloadURL(res.ref);
            newform.family[i].profilePic = url;
          } catch {
            console.log('');
          }
        }
      }

      for (let i = 0; i < newform.family.length; i++) {
        const ref = doc(collection(db, 'users'), newform.family[i].id);
        try {
          const ddoc = await getDoc(ref);
          if (!ddoc.exists()) {
            await setDoc(ref, newform.family[i] as any);
          } else {
            await updateDoc(ref, newform.family[i] as any);
          }
        } catch (e) {
          await setDoc(ref, newform.family[i] as any);
        }
      }
    }

    const usersRef = collection(db, 'users');
    const userRef = doc(usersRef, user.uid);
    try {
      await updateDoc(userRef, newform as any);
      setSaving(false);
      setSnackbarSeverity('success');
      setSnackbarMessage('Profile Updated');
      setSnackbarOpen(true);
    } catch (e) {
      setSnackbarSeverity('error');
      setSnackbarMessage(`Error updatign profile`);
      setSaving(false);
    }
  };
  const handleSnackbarClose = (reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  if (busy)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: { xs: 'center', md: 'flex-start' },
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <ProfilePicUploader
        profilePic={formState.profilePic}
        onProfilePhotoUpdated={(profilePic) => {
          setFormState((p) => ({ ...p, ...{ profilePic } }));
        }}
      />

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: { md: '50%', xs: '100%' } }}
      >
        <Grid item md={12} xs={12}>
          <Divider textAlign="left">Contact Info</Divider>{' '}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="firstName"
            label="First Name"
            variant="outlined"
            fullWidth
            value={formState.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth
            value={formState.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={user.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={formState.phoneError}
            id="phoneNumber"
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={formState.phoneNumber}
            onChange={handleChange}
            helperText={formState.phoneError ? 'Invalid phone number' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="addressLine1"
            label="Address"
            variant="outlined"
            fullWidth
            value={formState.addressLine1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="addressCity"
            label="City"
            variant="outlined"
            fullWidth
            value={formState.addressCity}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="addressState"
            label="State"
            variant="outlined"
            fullWidth
            value={formState.addressState}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            id="addressZip"
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={formState.addressZip}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.receiveCommunication}
                onChange={(x) => {
                  setFormState((f) => ({
                    ...f,
                    ...{ receiveCommunication: x.target.checked },
                  }));
                }}
                name="optInEmail"
                color="primary"
                id="receiveCommunication"
              />
            }
            label="I want to receive text & email communications from NMZ"
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
          <Divider textAlign="left">Birthday Info (Optional)</Divider>{' '}
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="day-select-label">Day</InputLabel>
            <Select
              labelId="day-select-label"
              id="day-select"
              value={formState.dobDay}
              onChange={(x) =>
                setFormState({
                  ...formState,
                  ...{ dobDay: x.target.value as number },
                })
              }
              label="Day"
            >
              {[...Array(31)]
                .map((_, i) => i + 1)
                .map((x) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="dobMonth"
              value={formState.dobMonth}
              onChange={(x) =>
                setFormState({
                  ...formState,
                  ...{ dobMonth: x.target.value as number },
                })
              }
              label="Month"
            >
              {months.map((month, index) => (
                <MenuItem key={month} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.includeInBirthdayEmails}
                onChange={(x) => {
                  setFormState((prev) => ({
                    ...prev,
                    ...{ includeInBirthdayEmails: x.target.checked },
                  }));
                }}
                name="optInBirtday"
                color="primary"
                id="includeInBirthdayEmails"
              />
            }
            label="Include my birthday in NMZ Birthday Announcements"
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
          <Divider textAlign="left">Family Members (Optional)</Divider>
        </Grid>
        {formState.family.map((x, index) => (
          <ChildInputs
            key={x.id}
            index={index}
            onChange={(x) => {
              const newChildren = [...formState.family];
              newChildren[newChildren.findIndex((c) => c.id === x.id)] = x;
              //if (newChildren.length === 0) return;
              setFormState((x) => ({ ...x, ...{ family: newChildren } }));
            }}
            handleDeleteChild={handleDeleteChild}
            childState={x}
          />
        ))}
        <Grid item xs={12} md={12}>
          <Button onClick={addChild} sx={{ ml: 3, mt: { xs: 1, md: 1 } }}>
            + Add Family Member
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            // type="submit"
            fullWidth
            disabled={!hasUpdated || busy || saving}
            onClick={save}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={(_, x) => handleSnackbarClose(x)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => handleSnackbarClose('')}
            severity={snackbarSeverity === 'success' ? 'success' : 'error'}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </Box>
  );
};

export default Info;
