import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Dialog,
  DialogContentText,
  Grid,
  Divider,
  InputAdornment,
} from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';
import { Print } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { submitBudgetForm } from '../../services';

interface TableRow {
  description: string;
  date: string;
  amount: number;
}

export interface IBudgetFormData {
  ministryName: string;
  submissionDate: string;
  ministryLeader: string;
  homeContact: string;
  cellContact: string;
  email: string;
  goals: string;
  comments: string;
  signature: string;
  signatureDate: string;
  activities: string;
  rows: TableRow[];
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function sumOfArray(numbers: number[]): number {
  let sum = 0;
  for (let i = 0; i < numbers.length; i++) {
    sum += numbers[i];
  }
  return sum;
}

const BudgetRequestForm = ({ form = null }: { form?: DocumentData | null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState<IBudgetFormData>({
    ministryName: form?.ministryName ?? '',
    submissionDate: form?.submissionDate ?? formatDate(new Date()),
    ministryLeader: form?.ministryLeader ?? '',
    homeContact: form?.homeContact ?? '',
    cellContact: form?.cellContact ?? '',
    email: form?.email ?? '',
    goals: form?.goals ?? '',
    comments: form?.comments ?? '',
    signature: form?.signature ?? '',
    signatureDate: form?.signatureDate ?? formatDate(new Date()),
    activities: form?.activities ?? '',
    rows: form?.rows ?? [{ description: '', date: '', amount: '0' }],
  });

  const handleAddRow = () => {
    setFormData({
      ...formData,
      rows: [...formData.rows, { description: '', date: '', amount: 0 }],
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex?: number
  ) => {
    const { name, value } = event.target;
    const rows = [...formData.rows];
    if (rowIndex !== undefined && rowIndex !== null)
      rows[rowIndex] = { ...rows[rowIndex], [name]: value };
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      rows: [...rows],
    });
  };

  // const handleChangex = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    submitBudgetForm(formData)
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const { ministryName, ministryLeader, email, signature, signatureDate } =
    formData;
  const isDisabled = !(
    ministryName &&
    ministryLeader &&
    email &&
    signature &&
    signatureDate
  );

  const formDisabled = form !== null;

  const print = () => {
    window.print();
  };
  const removeRow = (index: number) => {
    //const newEventDetails = [...formData.eventDetails];
    //newEventDetails.splice(index, 1);
    // setFormData({ ...formData, eventDetails: newEventDetails });
    const rows = formData.rows;
    rows.splice(index, 1);
    setFormData({
      ...formData,
      rows,
    });
  };
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container sx={{ width: { md: '50%', xs: '100%' } }}>
        <Typography variant="h5" align="center" gutterBottom>
          2025 FY BUDGET REQUEST FORM
        </Typography>
        {/* {!formDisabled && (
          <a
            href="/budget-planning-letter-2024"
            target="_blank"
            style={{ textAlign: 'center' }}
          >
            2024 Budget Planning Letter
          </a>
        )} */}
        {!formDisabled && (
          <Typography align="center" gutterBottom>
            <a
              href="/budget-planning-letter-2025"
              target="_blank"
              style={{ textAlign: 'center' }}
            >
              2025 Budget Planning Letter
            </a>
          </Typography>
        )}
        {formDisabled && (
          <div className="no-print">
            <Button variant="contained" startIcon={<Print />} onClick={print}>
              Print Completed Form
            </Button>
          </div>
        )}

        <Typography
          variant="body1"
          align="center"
          paragraph
          style={{ color: 'red' }}
        >
          Deadline for Submission: Thursday, September 26, 2024
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Name of Ministry"
            name="ministryName"
            value={formData.ministryName}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Submission Date"
            name="submissionDate"
            type="date"
            value={formData.submissionDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Ministry Leader or Event Leaders Name"
            name="ministryLeader"
            value={formData.ministryLeader}
            onChange={handleChange}
          />
          <Typography sx={{ mt: 1 }}>Contact Information</Typography>
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Home Phone number"
            name="homeContact"
            value={formData.homeContact}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Cell Phone number"
            name="cellContact"
            value={formData.cellContact}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />

          <Typography sx={{ mt: 1, fontWeight: 'bold' }}>
            ANNUAL MINISTRY PLAN WITH BUDGET DETAILS
          </Typography>

          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="2025 MINISTRY GOALS"
            name="goals"
            value={formData.goals}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="2025 MINISTRY ACTIVITIES/EVENTS"
            name="activities"
            value={formData.activities}
            onChange={handleChange}
            multiline
            rows={4}
          />

          <Typography sx={{ mt: 1, fontWeight: 'bold', mb: 2 }}>
            2025 EVENT MINISTRY TIMELINE AND REQUESTED FUNDS
          </Typography>
          <Grid container spacing={2}>
            {formData.rows.map((row, index) => (
              <React.Fragment key={index}>
                <Grid item xs={index === 0 ? 12 : 11}>
                  <FormTextField
                    disabled={formDisabled}
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={row.description}
                    onChange={(event) => handleChange(event, index)}
                  />
                </Grid>
                {index !== 0 && (
                  <Grid item xs={1}>
                    <Button onClick={() => removeRow(index)}>Remove</Button>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormTextField
                    disabled={formDisabled}
                    name="date"
                    label="Date of Activity/Event"
                    type="date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={row.date}
                    onChange={(event) => handleChange(event, index)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    disabled={formDisabled}
                    name="amount"
                    label="Amount Requested"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={row.amount}
                    onChange={(event) => handleChange(event, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ margin: 1 }} />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <Button color="primary" onClick={handleAddRow}>
            Add More
          </Button>
          <Typography sx={{ fontWeight: 'bold' }}>
            Total Amount Requested:
            {sumOfArray(
              formData.rows.map((x) => Number(x.amount))
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Comments and Additional Information"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Signature of Ministry Leader/Event Planner"
            name="signature"
            value={formData.signature}
            onChange={handleChange}
          />
          <FormTextField
            disabled={formDisabled}
            fullWidth
            margin="normal"
            label="Date"
            name="signatureDate"
            type="date"
            value={formData.signatureDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Form submitted. You will be contacted shortly
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        <Typography variant="body1" align="center" paragraph sx={{ mt: 2 }}>
          Thank you for completing the 2025 Budget Request Form. Please contact
          the administrative office with questions.
        </Typography>
      </Container>
    </Box>
  );
};

export default BudgetRequestForm;
