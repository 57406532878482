/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Button, Checkbox } from '@mui/material';
//import { Checkbox } from '@mui/material';
// import { doc, updateDoc } from 'firebase/firestore';
// import { db } from '../../App';

const SubmittedMental = ({ forms }: { forms: any[] }) => {
  const [tableClassName, setTableClassName] = useState('');
  //console.log(3);
  const [sorting, setSorting] = React.useState<SortingState>([
    // {
    //   id: 'name',
    //   desc: false,
    // },
  ]);

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'index',
        cell: (v) => (
          <a
            target="_blank"
            href={`https://console.firebase.google.com/project/nmzlakeland-a8568/firestore/data/~2Fforms~2F${v.row.original.id}`}
            rel="noreferrer"
          >
            {v.row.original.index}
          </a>
        ),
      },
      {
        header: 'FirstName',
        accessorKey: 'firstName',
      },
      {
        header: 'LastName',
        accessorKey: 'lastName',
      },
      //   {
      //     header: 'email',
      //     accessorKey: 'email',
      //   },
      // {
      //   header: 'Email',
      //   accessorKey: 'email',
      // },
      {
        header: 'Checked-In',
        accessorKey: 'name',
        cell: () => <Checkbox sx={{ m: 0, p: 0 }} />,
      },
      // {
      //   header: 'Complimentary Shirts',
      //   accessorKey: 'complimentaryShirts',
      //   cell: (v) => (
      //     <Checkbox
      //       onChange={(e) =>
      //         setComplimentary(v.row.original.id, e.target.checked)
      //       }
      //       checked={v.row.original.complimentaryShirts === true}
      //     />
      //   ),
      // },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      forms
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map((f, idx) => ({
          ...f,
          ...{ name: `${f.firstName} ${f.lastName}`, index: idx + 1 },
        })),
    [forms]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="p-2">
      <div className="no-print">
        <Button
          variant="contained"
          sx={{ mb: 1 }}
          onClick={() => {
            setTableClassName('mental-table');
            window.print();
          }}
        >
          PRINT
        </Button>
      </div>
      <table style={{ borderCollapse: 'collapse' }} className={tableClassName}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      border: '1px solid black',
                      padding: '10px',
                      backgroundColor: '#ddd',
                      cursor: 'pointer',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 1000)
            .map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{ border: '1px solid black', padding: '5px' }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SubmittedMental;
