import { Box } from '@mui/system';
import React from 'react';
import { colors } from '../../utils';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

const TimelineHeader = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      color: colors.themeRed,
      fontWeight: 'bold',
      fontSize: '1.5rem',
    }}
  >
    {children}
  </div>
);
const T10 = () => {
  return (
    <Box sx={{ lineHeight: '2rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/park_praise.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
          <div
            style={{
              color: 'white',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1.5rem',
            }}
          >
            Pastor Edward L. Quary, Jr.
          </div>
        </Box>
        <Box
          sx={{
            margin: '0rem',
            flex: 1,
            backgroundColor: 'white',
            padding: {
              xs: '1rem',
              md: '2rem',
            },
          }}
        >
          <p>
            During the church’s closure for in-person worship, Pastor Quary
            implemented the Park and Praise Outdoor Worship Ministry and
            enhanced our virtual footprint to reach a worldwide audience. During
            this time, Sunday School continued to be held every Sunday via a
            telephone conference line.
          </p>
          <h2 style={{ color: colors.themeRed }}>REOPENING THE CHURCH</h2>
          <p>
            Pastor Quary implemented a return to church COVID protocol to
            successfully and safely re-opened the church for in-person worship.
            The church reopened on May 2, 2021, more than one year after the
            initial closure. Since its re-opening, the church has continued to
            grow and maintain a robust virtual presence facilitated by
            establishing an Audio-Visual Ministry.
          </p>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          //padding: '2rem',
        }}
      >
        <Box style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>THE GROWTH OF THE CHURCH</h2>
          <p>
            While church growth continues to decline in many churches, New Mt.
            Zion continues to soar. The pathway to our growth has been enhanced
            by a growing number of young families and especially the youth.
            God’s generational grace has kept the church strong.
          </p>
          <h2 style={{ color: colors.themeRed }}>
            GENERATIONAL GRACE AND LEADERSHIP
          </h2>
          <p>
            While emphasizing generational grace and recognizing the five
            generations of members within the church, Pastor Quary implemented a
            plan to focus on developing new and emerging leaders. Another
            primary focus has been to move outside the church’s walls to
            evangelize and engage the Lakeland Community and beyond. Many souls
            are being saved.
          </p>
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/church1.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/church2.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            margin: '0rem',
            flex: 1,
            padding: {
              xs: '1rem',
              md: '2rem',
            },
          }}
        >
          <h2 style={{ color: colors.themeRed }}>
            MOVING OUTSIDE THE WALLS OF THE CHURCH TO EVANGELIZE AND SERVE THE
            COMMUNITY
          </h2>
          <p>
            Under Pastor Quary’s anointed leadership, the church has developed a
            vital evangelism ministry to share the good news of salvation,
            forgiveness, and grace. Without Jesus, we would all be lost without
            hope and a savior.
          </p>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <h2 style={{ color: colors.themeRed }}>
            COMMUNITY OUTING AND TENT REVIVAL AUGUST 29-31,2022
          </h2>

          <img
            src={require('../../images/church3.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <h2 style={{ color: colors.themeRed }}>OUTDOOR WORSHIP SERVICE</h2>
          <img
            src={require('../../images/church4.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: {
            xs: 'center',
            md: 'flex-start',
          },
          backgroundColor: colors.themeRed,
          color: 'white',
        }}
      >
        <Box sx={{ margin: '1rem' }}>
          <img
            src={require('../../images/covid.jpg')}
            alt="church"
            style={{ height: '200px' }}
          />
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <p>
            New Mt. Zion endured the effects of the unprecedented COVID-19
            worldwide pandemic that changed many cultural traditions at our
            church; however, it did not change our mission. New Mt Zion closed
            its doors for in-person worship services in March 2020. During this
            time, the church continued to worship, teach God’s Word, and support
            the members through live-streamed services.
          </p>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
          //padding: '2rem',
        }}
      >
        <Box style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>
            HEALTH-RELATED COMMUNITY OUTREACH
          </h2>
          <p>
            Pastor Quary believes in caring for the whole person and that we
            prosper in health while using our bodies as temples. Therefore, the
            church is actively involved in community health initiatives.
          </p>
          <h2 style={{ color: colors.themeRed }}>VACCINE AND BOOSTER CLINIC</h2>
          <p>
            During the pandemic, New Mt. Zion maintained community-wide
            leadership and partnered with the local health department to test,
            vaccinate, and provide boosters for upwards of two thousand
            community members.
          </p>
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/outreach1.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/cancer-awareness.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            margin: '0rem',
            flex: 1,
            padding: {
              xs: '1rem',
              md: '2rem',
            },
          }}
        >
          <h2 style={{ color: colors.themeRed }}>
            BREAST CANCER AWARENESS COMMUNITY SEMINAR -HONORING SURVIVORSHIP
            WITH GOD ALL THINGS ARE POSSIBLE
          </h2>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
          //padding: '2rem',
        }}
      >
        <Box style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>
            FORTY FOR FORTY MORTGAGE PAY-OFF GOAL
          </h2>
          <p>
            Pastor Quary implemented the Forty for Forty plan to pay the
            church’s mortgage in forty months. The church also obtained 501c3
            tax status. In addition, communications have been improved by
            implementing Dail My Calls and Zoom.
          </p>
          <h2 style={{ color: colors.themeRed }}>SAFETY AND SECURITY</h2>
          <p>
            The church’s foremost goal is to provide a safe and secure place of
            worship where members and visitors may worship comfortably with the
            assurance that all reasonable measures are being taken to ensure
            their safety.
          </p>
        </Box>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/choir1.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>

      <p>
        Under the prayerful leadership of Pastor Quary, Jr., comprehensive
        corporate-wide safety and security program was implemented on September
        18, 2022. The program was developed in response to threatening
        conditions or situations. These situations may present themselves in
        many ways, including weather conditions, physical violence, property
        thefts, disruptive individuals, and crimes against children.
      </p>
      <h2 style={{ color: colors.themeRed }}>
        HUMBLE BEGINNINGS HAVE LED US TO GREAT SERVICE- PUTTING GOD FIRST
      </h2>

      <p>
        While New Mt. Zion started from humble beginnings, the Lord has shown us
        abundant favor and generational grace throughout the years. Our
        Spiritual journey began in 1929 and continues to reveal itself as the
        years go by. We know that God will continue to lead, guide, and bless
        our church. Let us continue to demonstrate our thankfulness and
        steadfast support.
      </p>
      <p>
        The songwriter wrote the following verse that describes our church’s
        faith journey.
      </p>
      <p>
        We have come a long way.
        <br /> Sometimes it was an uphill journey. <br /> Sometimes on the rough
        side of the mountain <br />
        But we have come a long way <br /> Leaning and depending on Jesus.{' '}
        <br />
        We look forward to many years of Christian Leadership and service under
        Pastor Quary.
      </p>
    </Box>
  );
};

const T1 = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          //padding: '2rem',
        }}
      >
        <div style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>CHURCH HISTORY</h2>
          <p>
            It is incredible to witness how God has kept New Mt. Zion Missionary
            Baptist Church Lakeland together for more than 93 years. With each
            year, the church becomes more vital in fulfilling its mission. Our
            continued growth is a testimony of God’s Favor and Grace. His Grace
            has been sufficient to guide and sustain the church and we give Him
            all the Glory.
          </p>
          <p>
            This historical narrative highlights the church’s pastoral leaders’
            tenure, leadership, and corporate accomplishments. However, God’s
            generational grace shines upon all the Saints of God, past, and
            present, who mentored others and role-modeled what it means to be a
            faithful servant of God. The church honors and respects the
            spiritual legacy of these Saints and affirms its thankfulness for
            the Kingdom’s impact they made.
          </p>
        </div>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/nmz_bg3.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>
      <h2 style={{ color: colors.themeRed }}>OUR HUMBLE BEGINNING</h2>
      <p>
        New Mt. Zion Missionary Baptist Church was organized in Lakeland,
        Florida, in April 1929 by a small group of Christian believers who
        fellowshipped together in a tent on West Fourth Street. The Holy Spirit
        led them to form New Mt. Zion Primitive Baptist Church from this humble
        beginning.
      </p>
      <p>
        Reverend A. Darby was called as the pastor and served the church from
        1929 through 1932. Under his leadership, the church was changed from New
        Mt. Zion Primitive Baptist church to New Mt. Zion Missionary Baptist
        Church. After Reverend Darby’s leadership tenure, Reverend Lawrence
        Smith served as the new pastor.
      </p>
    </div>
  );
};

const T2 = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
          //padding: '2rem',
        }}
      >
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/nmz_bg4.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
        <div style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>
            NEW MT. ZION MISSIONARY BAPTIST CHURCH 1930’s
          </h2>
          <p>
            The church moved from the tent into a six-room house on the corner
            of Texas Avenue and Fourth Street. The church remained at this
            location for one year under the leadership of Reverend “Jumping Joe”
            Williams. The church was again relocated to a building on West Sixth
            Street and Texas Avenue under the leadership of Reverend Brooks.
            Under his leadership, the church purchased a building on the corner
            of Third Street and Ohio Avenue for fifty dollars. Reverend
            Lattimore and later Reverend Wilson served as the pastor.
          </p>
        </div>
      </Box>
      <h2 style={{ color: colors.themeRed }}>
        NEW MT. ZION MISSIONARY BAPTIST CHURCH 1945-1968 THIRD AND OHIO
      </h2>
      <p>
        In this location, Reverend David Smith served as the pastor from 1938 to
        1941. He was followed by Reverend D. Dukes, who served as pastor from
        1941 to 1945. The church was chartered during this time. New Mt. Zion
        was without a permanent pastor from 1945 to 1954. The church worshiped
        with Macedonia Primitive Baptist Church of Lakeland during this time.
      </p>
    </div>
  );
};

const T3 = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          //padding: '2rem',
        }}
      >
        <div style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>
            REVEREND CALVIN HENRY BOLDEN’S ERA OF LEADERSHIP
          </h2>
          <h3 style={{ color: colors.themeRed }}>PASTOR C.H. BOLDEN</h3>
          <p>
            <b>REVEREND CALVIN HENRY BOLDEN</b> served as the part-time interim
            pastor of the church in 1954. During this time, the church had
            approximately 80 members. Reverend Bolden became the full-time
            pastor in 1957. Under his leadership, the membership soared from
            eighty to Three hundred fifty members.
          </p>
          <p>
            The church became affectionately known as “Holy Ghost Headquarters.”
          </p>
        </div>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          <img
            src={require('../../images/bolden.png')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          />
        </Box>
      </Box>
    </div>
  );
};

const T4 = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          //padding: '2rem',
        }}
      >
        <div style={{ margin: '0rem', flex: 1 }}>
          <h2 style={{ color: colors.themeRed }}>
            NEW MT. ZION MISSIONARY BAPTIST CHURCH WEBSTER AVENUE LOCATION- 1968
          </h2>
          <p>
            The church moved from its Third Street and Ohio Avenue location to
            the current location on Webster Avenue and West Ninth Street in
            1968. During his leadership, the church building was paid off in
            1968. Many other remarkable changes occurred during his tenure. Most
            importantly, he encouraged the members to put on the “whole Armor of
            God.”
          </p>
          <p>
            He stressed the importance of Christians being spiritually active,
            civic-minded, and concerned with the whole man.
          </p>
        </div>
        <Box sx={{ flex: 1, margin: '1rem' }}>
          {/* <img
            src={require('../../images/nmz_bg5.jpg')}
            alt="church"
            width="100%"
            style={{ height: 'auto' }}
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.themeRed,
          color: 'white',
          padding: '1rem',
        }}
      >
        <h2>THE SONS OF NEW MT. ZION</h2>
        <p>
          Many young men received their call to preach the word under the
          anointed leadership of Reverend Bolden. He opened the pulpit to them,
          assisted them in their ministerial walk, and served as a mentor and
          role model to them. They became known as the Sons of New Mt. Zion.
          This designation is still relevant and remains an essential point of
          our generational legacy.
        </p>
        <h2>THE END OF AN ERA</h2>
        <p>
          Reverend Bolden served as pastor for 27 years until his death in 1981.
          The C.H. Bolden Fellowship Hall was named in his honor.
        </p>
        <h2>PASTORS WHO CONTINUED TO ANSWER THE CALL</h2>
        <p>
          <b>REVEREND LUERAY NORRIS</b> was called as pastor and served the
          church for 10 years from1984 through 1994. The church grew spiritually
          under his leadership. His spiritual motto was “Love is the Reason.” He
          continued the tradition of opening the pulpit to the Sons of New Mt.
          Zion. <b>REVEREND JOE S. HARDIE</b> of Portland, Oregon, was called as
          pastor on July 30, 1995, and accepted this call on September 3, 1995.
          He was installed on October 1, 1995
        </p>
      </Box>
    </div>
  );
};
const T5 = () => {
  return (
    <div>
      <h2 style={{ color: colors.themeRed, marginTop: '3rem' }}>
        A NEW WORSHIP FACILITY
        <br />
        NEW MT. ZION MISSIONARY BAPTIST CHURCH- AUGUST 1997
      </h2>
      <p>
        Under Reverend Hardies’ leadership, our present worship facility, which
        seats approximately one thousand people, was built. A groundbreaking
        ceremony was held on Wednesday, April 17, 1996. Construction began in
        1996, and the building was completed in August 1997.
      </p>
    </div>
  );
};
const T6 = () => {
  return (
    <div>
      <h2 style={{ color: colors.themeRed }}>
        THE C.H. BOLDEN FELLOWSHIP HALL
      </h2>
      <Box
        sx={{
          flex: 1,
          margin: '1rem',
          width: {
            xs: '100%',
            md: '50%',
          },
        }}
      >
        <img
          src={require('../../images/nmz_bg6.jpg')}
          alt="church"
          width="100%"
          style={{ height: 'auto' }}
        />
      </Box>
      <p>
        The “old church was completely renovated in 1998. The building was
        dedicated and named the C.H. Bolden Fellowship Hall in memory of
        Reverend Bolden. The building is still in use today. Pastor Hardies’
        tenure as pastor ended on April 28, 2009.
      </p>
    </div>
  );
};

const T7 = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          backgroundColor: colors.themeRed,
          color: 'white',
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            // flex: 1,
            margin: '1rem',
            width: {
              xs: '100%',
              md: '50%',
            },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={require('../../images/kendrix.png')}
            alt="church"
            width="100%"
            style={{ height: 'auto', maxWidth: '200px' }}
          />
        </Box>
        <Box>
          <p>
            <b>REVEREND KENDRIX J. GARDNER, SR.</b>, of Valdosta, Georgia, was
            called to pastor New Mt. Zion on Tuesday, June 8, 2019, after a
            period of deliberation, fasting, and prayer.
          </p>
          <p>
            The church family held a welcome celebration for the new pastor and
            his family on July 11, 2010, with the theme of “God’s Rainbow for
            New Mt. Zion.” Pastor Gardner was officially installed on Sunday,
            September 19, 2010.
          </p>
        </Box>
      </Box>
      <p>
        The church experienced tremendous growth, spiritually, financially, and
        numerically under Pastor Gardner’s leadership. The church was organized
        into ministries, a radio ministry was established at WWAB, and the
        church launched its first website. The church, now valued at more than
        one and a half million dollars, was refinanced in 2013. The refinancing
        saved the church more than one million dollars in interest.
      </p>
      <p>
        Property improvements and enhancements included renovating the C.H.
        Bolden Fellowship Hall, repaving the main parking lot, and purchasing an
        additional parking lot between West 7th and 8th Street. A
        state-of-the-art sound system was installed and lifesaving AED equipment
        was placed in the church buildings during this period.
      </p>
      <h2 style={{ color: colors.themeRed }}>DEACON EMERITUS CEREMONY</h2>
      <p>
        New Mt. Zion held its first ceremony and dinner in honor of four
        retiring Deacons on January 24, 2016. Webster Broderick, William Gatlin,
        Nathaniel Robinson, and Jimmy Ward were chosen for this designation.
        These Deacons were elevated to Deacon Emeritus status by Pastor Gardner.
      </p>
    </div>
  );
};

const T8 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        alignItems: 'center',
        //padding: '2rem',
      }}
    >
      <div style={{ margin: '0rem', flex: 1 }}>
        <h2 style={{ color: colors.themeRed }}>
          THE MULTIPURPOSE CENTER- JANUARY 2020
        </h2>
        <p>
          A house located behind the fellowship hall at 924 W. 9th Street was
          purchased on May 19, 2019. The house was completely renovated as a
          multi-purpose center. The Center is a positive asset that will be used
          to satisfy the various needs and services of the church and community
          for years to come. The renovation was completed in December 2019, and
          the building was dedicated on Sunday, January 19, 2020.
        </p>
        <p>
          Pastor Gardner resigned on March 1, 2020, after serving ten years as
          pastor.
        </p>
      </div>
      <Box sx={{ flex: 1, margin: '1rem' }}>
        <img
          src={require('../../images/home.jpg')}
          alt="church"
          width="100%"
          style={{ height: 'auto' }}
        />
      </Box>
    </Box>
  );
};

const T9 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        alignItems: 'center',
        backgroundColor: colors.themeRed,
      }}
    >
      <Box sx={{ flex: 1, margin: '1rem' }}>
        <img
          src={require('../../images/pastor1.jpg')}
          alt="church"
          width="100%"
          style={{ height: 'auto' }}
        />
        <div
          style={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          Pastor Edward L. Quary, Jr.
        </div>
      </Box>
      <Box
        sx={{
          margin: '0rem',
          flex: 1,
          backgroundColor: 'white',
          padding: {
            xs: '1rem',
            md: '2rem',
          },
        }}
      >
        <h3>A NEW ERA – A SON COMES HOME</h3>
        <h2>PANDEMIC LEADERSHIP AND BEYOND</h2>
        <p>
          <b>REVEREND EDWARD L. QUARY, JR.</b>, a Son of New Mt. Zion and a
          lifelong church member, was called as pastor on September 19, 2020,
          during the historic and unprecedented COVID-19 pandemic. He began his
          pastoral leadership on November 1, 2020, and was officially installed
          on Sunday, July 11, 2021. His inaugural theme was “God Did It.”
        </p>
        <p>
          While the membership of many churches declined during the pandemic,
          and many closed their doors permanently, New Mt. Zion has soared to
          new heights spiritually, numerically, and financially under Pastor
          Quary’s leadership. The church has moved forward by faith. Despite the
          pandemic, his inspirational teaching series has generated a spiritual
          revival within the membership.
        </p>
      </Box>
    </Box>
  );
};

const ChurchHistory = () => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>April 1929</TimelineHeader>
          <T1 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>{"1930's"}</TimelineHeader>
          <T2 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>1957</TimelineHeader>
          <T3 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>1968</TimelineHeader>
          <T4 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>August 1997</TimelineHeader>
          <T5 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>1998</TimelineHeader>
          <T6 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>2019</TimelineHeader>
          <T7 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>January 2020</TimelineHeader>
          <T8 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>2020</TimelineHeader>
          <T9 />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ padding: '0.5rem' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimelineHeader>2021</TimelineHeader>
          <T10 />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
export default ChurchHistory;
