/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { db } from '../../App';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FormIds } from '../../services';
import SubmittedBreastCancer from './SubmittedBreastCancer';
import SubmittedMental from './SubmittedMental';
import SubmittedSchoolBash from './SubmittedSchoolBash';
import { User } from '../../types';
import { sundaySchoolTeachers } from '../../utils';

interface Zaga {
  formId: string;
  formName: string;
  forms: any[];
}
const getByDisplayValue = (form: any) => {
  if (form.formId === FormIds.breastCancerSeminar2024) {
    const n = form.name;
    return (
      n +
      (form.orderTshirt === true ? ` (T-shirt size: ${form.tshirtSize})` : '')
    );
  }
  if (form.name) return form.name;
  if (form.firstName)
    return form.firstName + (form.lastName ? ' ' + form.lastName : '');
  if (form.signature) return form.signature;
  return form.id;
};
const formIdToName = (id: string) => {
  if (id === FormIds.volunteer) return 'Employment/Volunteer';
  if (id === FormIds.breastCancerSeminar2024)
    return 'Breast Cancer 2024 Seminar Registration';
  if (id === FormIds.schoolBash2024) return 'Back to School Bash';
  if (id === FormIds.budgetForm2024) return '2024 Budget Request Form';
  if (id === FormIds.budgetForm2025) return '2025 Budget Request Form';

  if (id === FormIds.mentalHealth2024) return '2024 Mental Health Symposium';
  return id;
};

const SubmittedForms = ({ user }: { user: User }) => {
  const [busy, setBusy] = useState(false);
  const [forms, setForms] = useState<Zaga[]>([]);
  const formsTofetch = useMemo(
    () =>
      !sundaySchoolTeachers.includes(user.email.toLowerCase())
        ? [
            FormIds.volunteer,
            FormIds.contact,
            FormIds.breastCancerSeminar2024,
            // FormIds.budgetForm2024,
            FormIds.budgetForm2025,

            //FormIds.mentalHealth2024,
            //FormIds.schoolBash2024,
          ]
        : [FormIds.schoolBash2024],
    [user.email]
  );
  useEffect(() => {
    const q = query(
      collection(db, 'forms'),
      where('formId', 'in', formsTofetch)
    );
    setBusy(true);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setBusy(false);
      const z: Zaga[] = [];
      querySnapshot.forEach((doc) => {
        const f: any = { ...doc.data(), ...{ id: doc.id } };
        //con
        if (f.formId) {
          const idx = z.findIndex((x) => x.formId === f.formId);
          if (idx === -1) {
            z.push({
              formId: f.formId,
              formName: formIdToName(f.formId),
              forms: [f],
            });
          } else {
            z[idx].forms.push(f);
          }
        }
      });
      setForms(
        z.sort((f) => (f.formId === FormIds.breastCancerSeminar2024 ? -1 : 1))
      );
    });
    return () => {
      unsubscribe();
    };
  }, [formsTofetch]);

  const orderForms = (ff: any[]) => {
    if (ff[0].formId === FormIds.breastCancerSeminar2024) {
      console.log({ ff });

      return ff.sort((a, b) => {
        const lastNameA = a.lastName;
        const lastNameB = b.lastName;

        if (lastNameA < lastNameB) return -1;
        if (lastNameA > lastNameB) return 1;
        return 0;
      });
    }
    return ff;
  };
  if (busy) return <CircularProgress />;

  const formLength = (f: Zaga) => {
    if (f.formId !== FormIds.schoolBash2024) return f.forms.length;
    const d = f.forms.map((x) => x.children).flat();
    return d.length;
  };

  function sanitizeGradeLevel(grade: string) {
    // Helper function to determine the suffix
    function getSuffix(number: number) {
      const lastDigit = number % 10;
      const lastTwoDigits = number % 100;

      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return 'th';
      }
      switch (lastDigit) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }

    // Extract the number from the input
    const gradeNumber = parseInt(grade, 10);

    // If the extraction fails, return the input unchanged
    if (isNaN(gradeNumber)) {
      return grade;
    }

    // Determine the suffix and return the formatted string
    const suffix = getSuffix(gradeNumber);
    return `${gradeNumber}${suffix}`;
  }

  const flattenForm = (f: Zaga[]) => {
    const form: any[] = [];
    for (let i = 0; i < f.length; i++) {
      const parent = f[i] as any;
      const children = parent.children;
      for (let j = 0; j < children.length; j++) {
        const child = children[j];
        child.gradeLevel = sanitizeGradeLevel(child.gradeLevel);
        form.push({ ...child, ...{ parent } });
      }
    }

    return form;
  };

  return (
    <Container>
      {forms.map((f) => (
        <div key={f.formId} style={{ margin: 5 }}>
          <Accordion defaultExpanded={formsTofetch.length === 1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="no-print"
            >
              <Typography className="no-print">
                {f.formName} - <b>({formLength(f)})</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {f.formId === FormIds.breastCancerSeminar2024 ? (
                <SubmittedBreastCancer forms={f.forms} />
              ) : f.formId === FormIds.mentalHealth2024 ? (
                <SubmittedMental forms={f.forms} />
              ) : f.formId === FormIds.schoolBash2024 ? (
                <SubmittedSchoolBash forms={flattenForm(f.forms)} />
              ) : (
                <ul>
                  {orderForms(f.forms).map((form) => (
                    <li key={form.id} style={{ margin: 5 }}>
                      <a
                        href={`/forms/${form.id}/completed`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getByDisplayValue(form)}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </Container>
  );
};

export default SubmittedForms;
