// src/components/Footer.tsx

import { Email, Facebook, Map, Phone, YouTube } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

const Footer = () => {
  return (
    <footer
      className="no-print"
      style={{
        backgroundColor: '#000f21',
        color: 'white',
        padding: '1rem',
        textAlign: 'center',
        flexShrink: 0,
      }}
    >
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Map style={{ color: '#bf0a30', marginRight: 5 }} />
        <span>1321 N. Webster Avenue, Lakeland, FL 33805</span>
      </p>
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Phone style={{ color: '#bf0a30', marginRight: 5 }} />
        <span>(863) 686-6793</span>
      </p>
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Email style={{ color: '#bf0a30', marginRight: 5 }} />
        <span> info@nmzlakeland.org</span>
      </p>
      <p>
        <IconButton
          aria-label="youtube"
          color="primary"
          href="https://www.youtube.com/channel/UC2OOtv9Mutzsd6pH5vu-BIA"
          style={{ color: 'white' }}
        >
          <YouTube />
        </IconButton>
        <IconButton
          aria-label="facebook"
          color="primary"
          style={{ color: 'white' }}
          href="https://www.facebook.com/profile.php?id=61558304579251"
        >
          <Facebook />
        </IconButton>
      </p>
      <p style={{ color: '#aaa', fontSize: '0.9rem', marginTop: 50 }}>
        &copy; {new Date().getFullYear()} New Mt Zion Missionary Baptist Church
        Lakeland. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
