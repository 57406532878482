import { Box } from '@mui/material';
import React from 'react';
import logo from '../../images/logo.png'; // Import your logo image here

const BudgetPlanningLetter = () => {
  return (
    <Box
      sx={{
        padding: 5,
        margin: 5,
        width: {
          lg: '50%',
        },
        border: '1px solid gray',
      }}
    >
      <img src={logo} />
      {/* <h3 style={{ textAlign: 'center', color: '#a5545a', fontWeight: 'bold' }}>
        New Mt. Zion Missionary Baptist Church
      </h3> */}
      <div style={{ display: 'flex', height: '5px' }}>
        <div style={{ flex: 1, backgroundColor: '#facc02' }}></div>
        <div style={{ flex: 1, backgroundColor: '#660066' }}></div>
        <div style={{ flex: 1, backgroundColor: '#facc02' }}></div>
      </div>
      <h2 style={{ textAlign: 'center' }}>2025 Budget Planning Letter</h2>
      <h3 style={{ textAlign: 'center' }}>DATE: AUGUST 28, 2024</h3>
      <p>Dear Ministry Leaders,</p>
      <h3 style={{ textAlign: 'center', color: 'purple' }}>
        TO GOD BE THE GLORY FOR THE WONDERFUL THINGS HE HAS DONE!
      </h3>
      <p>
        Greetings in the name of our Lord and Savior, Jesus Christ! It is time
        to develop our annual budget for the 2025 fiscal year. We are grateful
        that our church mortgage is paid in full through God’s Amazing Grace.
        Pastor Quary’s 2025 ministry focus includes Making Disciples by teaching
        the Word of God, Evangelism, the H.O.M.E. Building (House of Mission and
        Evangelism), Audiovisual Enhancements, and Children and Youth. Most of
        our budget dollars will be spent in these areas.
      </p>
      <p>
        Our church budget is the plan for church operations based on our goals
        for the year, historical data, estimated income, and expenses. It is one
        of our most important planning tools. For this reason, we request that
        you carefully consider your 2025 ministry goals as you complete your
        budget request form.
      </p>
      <p>
        Budget forms will be distributed via our website:{' '}
        <a href="www.nmzlakeland.org/members/forms">
          www.nmzlakeland.org/members/forms
        </a>
        , and we request that you submit them electronically. Please adhere to
        the budget timeline below to ensure timely completion
      </p>
      <table border={1} cellPadding="5" cellSpacing="0">
        <thead>
          <tr>
            <th style={{ backgroundColor: '#ffdc9e' }}>TIMELINE</th>
            <th style={{ backgroundColor: '#ffdc9e' }}>ACTIVITY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Thursday, September 5, 2024</td>
            <td>The 2025 Budgeting Process Begins</td>
          </tr>
          <tr>
            <td>Thursday, September 5, 2024</td>
            <td>
              Budget letters and forms are distributed and online via the
              website{' '}
              <a href="www.nmzlakeland.org/members/forms">
                www.nmzlakeland.org/members/forms
              </a>
            </td>
          </tr>
          <tr>
            <td>Thursday, September 26, 2024</td>
            <td>Final date for electronic submission of budget forms.</td>
          </tr>
        </tbody>
      </table>
      <p>
        We appreciate your prayerful, thorough, and timely completion of the
        budget request forms and look forward to an amazing year of service to
        our Lord. To God Be The Glory!
      </p>
      <p>Sincerely,</p>
      <p>Pastor Quary and The New Mt. Zion Budget Committee</p>
      <p>
        Sister Barbara Harrison, Chair, Deacon Jesse Moore, and Sister Doretha
        Brooks, Church Accountant
      </p>
    </Box>
  );
};

export default BudgetPlanningLetter;
