import { Box, Button } from '@mui/material';
import React, { useRef, useState } from 'react';

const WebcamRecorder = ({
  onRecord,
  url,
}: {
  url: string;
  onRecord: (url: string) => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const previewRef = useRef<HTMLVideoElement>(null);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const startRecording = (): void => {
    onRecord('');
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream: MediaStream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        setRecording(true);
        let options;
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          options = { mimeType: 'video/webm;codecs=vp8' };
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
          options = { mimeType: 'video/webm' };
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
          options = { mimeType: 'video/mp4' };
        } else {
          console.error('No supported video MIME types found');
        }

        console.log(options);
        const recorder = new MediaRecorder(stream, options);
        setMediaRecorder(recorder);

        const chunks: Blob[] = [];
        recorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data);

        recorder.onstop = () => {
          const completeBlob = new Blob(chunks, { type: chunks[0].type });
          const videoUrl = URL.createObjectURL(completeBlob);
          console.log(videoUrl); // You can download or upload the blob from here

          onRecord(videoUrl);
          if (previewRef.current) {
            previewRef.current.src = videoUrl;
            previewRef.current.load(); // Reset video playback
            previewRef.current.play();
          }
        };

        recorder.start();
      })
      .catch((err: Error) => alert('Error' + err.toString()));
  };

  const stopRecording = (): void => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track: MediaStreamTrack) => track.stop());
      }
      setRecording(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* {recording && ( */}
      {!url && (
        <video
          ref={videoRef}
          autoPlay
          muted
          height={320}
          style={{ border: '1px solid', background: 'gray' }}
        ></video>
      )}
      {/* )} */}

      {url && <video src={url} controls height={320} ref={previewRef}></video>}

      <Button
        onClick={recording ? stopRecording : startRecording}
        variant="contained"
        color={recording ? 'warning' : 'success'}
      >
        {recording ? 'Stop Recording' : 'Start Recording'}
      </Button>
    </Box>
  );
};

export default WebcamRecorder;
