import React, { useState } from 'react';
import { Box } from '@mui/system';
import LoginPage from './LoginPage';
import { User } from 'firebase/auth';
import Info from './Info';

const Profile = () => {
  const [user, setUser] = useState<User | null | undefined>();
  return (
    <Box component={'span'}>
      {user && <Info user={user} />}
      <LoginPage onUserSet={setUser} />
    </Box>
  );
};

export default Profile;
