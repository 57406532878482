/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  CircularProgress,
  Button,
  FormControl,
  MenuItem,
  Select,
  Container,
  InputLabel,
  Alert,
  Snackbar,
  List,
  ListSubheader,
  Typography,
  TextField,
} from '@mui/material';
import {
  createNewAnnouncement,
  deleteAnnouncement,
  useAnnouncements,
} from '../../services';
import { User } from '../../types';

const Announcements = ({ user }: { user: User }) => {
  const { busy, announcements } = useAnnouncements(true);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState(0);
  const [callToAction, setCallToAction] = useState('');
  const [link, setLink] = useState('');
  const date = new Date();

  // Add five days to current date
  date.setDate(date.getDate() + 7);
  const [displayUntil, setDisplayUntil] = useState(
    date.toISOString().split('T')[0]
  );

  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);
    createNewAnnouncement({
      id: '',
      text,
      callToAction,
      link,
      createdOn: new Date(),
      creatorId: user.userId,
      displayUntil: new Date(displayUntil + 'T12:00:00'),
      enabled: true,
    }).then(() => {
      setSaving(false);
      setOpen(true);
      setText('');
      setLink('');
      setCallToAction('');
    });
  };

  if (!user?.isAdmin) return null;

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          //width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <h2>Post a new Announcement</h2>
        <FormControl style={{ marginBottom: '10px', minWidth: '200px' }}>
          <InputLabel id="day-select-label">Announcement Type</InputLabel>
          <Select
            labelId="day-select-label"
            value={type}
            onChange={(e) => {
              setType(e.target.value as number);
            }}
            displayEmpty
            required
            label="Announcement Type"
          >
            <MenuItem value={0}>Text Banner Only</MenuItem>
            <MenuItem value={1}>Text Banner with Action Button</MenuItem>
          </Select>
          <TextField
            label="Text"
            value={text}
            onChange={(x) => setText(x.target.value)}
            style={{
              marginBottom: '1rem',
              minWidth: '400px',
              marginTop: '1rem',
            }}
            placeholder="Enter announcement banner message"
            multiline
          />
          {type === 1 && (
            <>
              <TextField
                label="Button Label"
                value={callToAction}
                style={{
                  marginBottom: '1rem',
                }}
                onChange={(e) => setCallToAction(e.target.value)}
                placeholder="Enter the Action Button Label"
              />
              <TextField
                label="Link"
                style={{
                  marginBottom: '1rem',
                }}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Enter url to link to"
              />
            </>
          )}

          <TextField
            label="Display Until"
            type="date"
            value={displayUntil}
            onChange={(x) => {
              setDisplayUntil(x.target.value);
            }}
            style={{
              marginBottom: '10px',
              marginTop: '1rem',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={busy ? <CircularProgress size={20} /> : null}
            disabled={
              saving || !text || (type === 1 && (!callToAction || !link))
            }
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            {saving ? 'Sending...' : 'Send'}
          </Button>
        </FormControl>
        <List
          subheader={
            <ListSubheader sx={{ mt: 5 }}>Announcements</ListSubheader>
          }
          sx={{ width: '80%' }}
        >
          {announcements.map((item) => (
            <Box
              key={item.id}
              sx={{
                p: 1,
                borderBottom: '1px solid #ddd',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography>{item.text}</Typography>
                {item.link && (
                  <>
                    <Typography color="GrayText" variant="caption">
                      <a href={item.link} target="_blank" rel="noreferrer">
                        {item.callToAction}
                      </a>
                    </Typography>
                  </>
                )}
                <Typography color="GrayText" variant="caption">
                  Display Until: {item.displayUntil.toLocaleDateString()}
                </Typography>
              </Box>
              <div style={{ flex: 1 }}></div>
              <Button onClick={() => deleteAnnouncement(item.id)}>
                Delete
              </Button>
              {/* <Button>Disable</Button> */}
            </Box>
          ))}
        </List>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => {
            setOpen(false);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="success"
            sx={{ width: '100%' }}
          >
            Announcement Banner Added to Main Page
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Announcements;
