// src/components/About.tsx

import { Place } from '@mui/icons-material';
import Button from '@mui/material/Button';
import React from 'react';

const Intro = () => {
  function getCurrentMonthString() {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentDate = new Date();
    return months[currentDate.getMonth()];
  }
  return (
    <div className="intro">
      <div>This is NMZ Lakeland</div>
      <div>Welcome Home</div>
      <div>
        Let us discover all the ways we can celebrate Christ and get closer to
        the Lord, together
      </div>
      <div>Worship with us every Sunday at 11AM</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '2rem',
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
      >
        <Place color="primary" style={{ color: 'white' }} />
        <a
          href="https://www.google.com/maps/place/1321+N+Webster+Ave,+Lakeland,+FL+33805"
          target="_blank"
          rel="noreferrer"
        >
          1321 N Webster Ave, Lakeland, FL 33805
        </a>
      </div>
      <Button
        variant="contained"
        href="/services"
        color="primary"
        style={{ backgroundColor: 'rgb(233, 32, 79)' }}
      >
        Learn More
      </Button>

      <Button
        variant="outlined"
        // variant="contained"
        href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2Fnmz_july_bulletin.pdf?alt=media&token=fd016a99-702c-4ea6-a2d0-c5c622f931be"
        target="_blank"
        color="primary"
        // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
        sx={{ mt: 2, borderColor: '#fff' }}
      >
        View {getCurrentMonthString()} Bulletin
      </Button>
    </div>
  );
};

export default Intro;
