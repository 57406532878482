/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import MicRecorder from 'mic-recorder-to-mp3';
const Recorder = ({
  onRecord,
  audioUrl,
}: {
  audioUrl: string;
  onRecord: (url: string) => void;
}) => {
  const [recorder, setRecorder] = useState<any>(null);
  const [isRecording, setisRecording] = useState(false);

  const [time, setRecordTime] = useState(0);
  useEffect(() => {
    setRecorder(
      new MicRecorder({
        bitRate: 128,
      })
    );
  }, []);
  const start = () => {
    if (!isRecording) {
      setisRecording(true);
      onRecord('');
      recorder
        .start()
        .then(() => {
          // something else
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      setisRecording(false);
      setRecordTime(0);
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]: any[]) => {
          // do what ever you want with buffer and blob
          // Example: Create a mp3 file and play
          const file = new File(
            buffer,
            `${new Date().toLocaleString().replace(' ', '')}.mp3`,
            {
              type: blob.type,
              lastModified: Date.now(),
            }
          );
          const url = URL.createObjectURL(file);
          onRecord(url);
          onRecord(url);
          //   const player = new Audio(URL.createObjectURL(file));
          //   player.play();
          //   player.controls = true;
        })
        .catch((e: any) => {
          alert('We could not retrieve your message');
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (!isRecording) return;
    const interval = setInterval(() => {
      setRecordTime((prev) => prev + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isRecording]);
  const seconds = `0${time % 60}`.slice(-2);
  const minutes = `0${Math.floor(time / 60) % 60}`.slice(-2);
  const hours = `0${Math.floor(time / 3600)}`.slice(-2);
  return (
    <Box>
      <Typography>
        {hours} : {minutes} : {seconds}
      </Typography>
      {audioUrl && (
        <div>
          <audio src={audioUrl} controls />
        </div>
      )}
      <Button
        onClick={start}
        variant="contained"
        color={isRecording ? 'warning' : 'success'}
      >
        {!isRecording ? 'Record' : 'Stop'}
      </Button>
    </Box>
  );
};

export default Recorder;
