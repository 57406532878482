export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isAdmin?: boolean;
  profilePic?: string;
}

export const ComTypes = {
  Email: 'Email',
  Text: 'Text',
  Audio: 'Audio',
  Video: 'Video',
};
export interface CommunicationType {
  id: string;
  subject: string;
  commType: string;
  value: string;
  date: Date;
  creator: User;
  audioUrl?: string;
}

export interface Employment {
  employer: string;
  position: string;
  startDate: string;
  endDate: string;
}

export interface Reference {
  name: string;
  email: string;
  homePhone: string;
  cell: string;
}

export interface EmploymentFormState {
  employment: Employment[];
  previousVolunteerExperience: string;
  specialSkills: string;
  references: Reference[];
  pastOrPresentProhibit: string;
  explanation: string;
  previousVolunteerOrgs: string;
}
