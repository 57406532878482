import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Grid,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  CircularProgress,
  Dialog,
  DialogContentText,
} from '@mui/material';
import { VolunteerInput, submitVolunteerForm } from '../../services';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';
import EmploymentForm from './EmploymentForm';
import { Print } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const VolunteerForm = ({ form = null }: { form?: DocumentData | null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [read, setRead] = useState(false);
  const defaultEmployment = {
    employment: Array(2).fill({
      employer: '',
      position: '',
      startDate: '',
      endDate: '',
    }),
    previousVolunteerExperience: '',
    references: Array(2).fill({ name: '', email: '', homePhone: '', cell: '' }),
    pastOrPresentProhibit: '',
    explanation: '',
    previousVolunteerOrgs: '',
  };
  const [values, setValues] = useState<VolunteerInput>({
    lastName: form?.lastName ?? '',
    firstName: form?.firstName ?? '',
    middleName: form?.middleName ?? '',
    address: form?.address ?? '',
    city: form?.city ?? '',
    state: form?.state ?? '',
    zipCode: form?.zipCode ?? '',
    homePhone: form?.homePhone ?? '',
    cellPhone: form?.cellPhone ?? '',
    email: form?.email ?? '',
    ministry: form?.ministry ?? '',
    acceptedJesus: form?.acceptedJesus ?? true,
    explanation: form?.explanation ?? '',
    churchHistory: form?.churchHistory ?? '',
    completedClass: form?.completedClass ?? false,
    volunteerExperience: form?.volunteerExperience ?? '',
    backgroundCheck: form?.backgroundCheck ?? false,
    employment: form?.employment ?? defaultEmployment,
    signature: form?.signature ?? '',
    signatureDate: form?.signatureDate ?? new Date().toLocaleDateString(),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    submitVolunteerForm(values)
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const {
    firstName,
    lastName,
    address,
    city,
    state,
    zipCode,
    cellPhone,
    homePhone,
    email,
    signature,
    signatureDate,
  } = values;
  const isDisabled = !(
    firstName &&
    lastName &&
    address &&
    city &&
    state &&
    zipCode &&
    (cellPhone || homePhone) &&
    email &&
    signature &&
    signatureDate &&
    read
  );

  const formDisabled = form !== null;

  const print = () => {
    window.print();
  };
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container>
        {/* {!form && (
          <a
            href={require(`../../images/volunteer_form.png`)}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require(`../../images/volunteer_form.png`)}
              className="ministry-details-image"
              style={{
                height: '300px',
                width: 'auto',
                margin: '1rem',
                cursor: 'pointer',
              }}
            />
          </a>
        )} */}
        <h3>Employment and Volunteer Application Form</h3>
        {/* <i>
          {`(Separate application for the Children's Ministry and the Safety and Security Ministry)`}
        </i> */}
        {/* <br /> <br /> */}
        {!formDisabled && (
          <Button
            variant="contained"
            onClick={() => {
              window.open(
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                require(`../docs/EMPLOYMENT_AND_VOLUNTEER_APPLICATION_FORM_FINAL_REVISION.docx`),
                '_blank'
              );
              //require(`../../docs/EMPLOYMENT_AND_VOLUNTEER_APPLICATION_FORM_FINAL_REVISION.docx`)
            }}
          >
            Download Form
          </Button>
        )}
        {formDisabled && (
          <div className="no-print">
            <Button variant="contained" startIcon={<Print />} onClick={print}>
              Print Completed Form
            </Button>
          </div>
        )}
        <p>
          {`New Mt. Zion Lakeland exists to share God's Word and His Love while serving as a Beacon of Light and Hope within the Lakeland Community. Volunteers play an essential role in the operations of our church, and we appreciate and thank you for your interest in serving at New Mt. Zion Missionary Baptist Church Lakeland. As church-based volunteers, we require that our ministry leaders and volunteers recognize that they serve as an example by modeling Christian behavior based on Biblical Teachings and serving with compassion, excellence, and love for one another.`}
        </p>
        <p>
          {`To help us with this process and ensure a safe environment, the church requires all employees and volunteers to complete a volunteer application form, a confidential background check, and the required specialized training.`}
        </p>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="firstName"
                label="First Name"
                fullWidth
                onChange={handleChange}
                value={firstName}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="middleName"
                label="Middle Name"
                fullWidth
                onChange={handleChange}
                value={values.middleName}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="lastName"
                label="Last Name"
                fullWidth
                onChange={handleChange}
                value={values.lastName}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="address"
                label="Address"
                fullWidth
                onChange={handleChange}
                value={values.address}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormTextField
                name="city"
                label="City"
                fullWidth
                onChange={handleChange}
                value={values.city}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormTextField
                name="state"
                label="State"
                fullWidth
                onChange={handleChange}
                value={values.state}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormTextField
                name="zipCode"
                label="Zip Code"
                fullWidth
                onChange={handleChange}
                value={values.zipCode}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="homePhone"
                label="Home Phone Number"
                fullWidth
                onChange={handleChange}
                value={values.homePhone}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="cellPhone"
                label="Cell Phone Number"
                fullWidth
                onChange={handleChange}
                value={values.cellPhone}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name="email"
                label="Email Address"
                fullWidth
                onChange={handleChange}
                value={values.email}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="ministry"
                label="POSITION OR MINISTRY YOU ARE APPLYING FOR"
                fullWidth
                onChange={handleChange}
                value={values.ministry}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Spiritual History</Typography>
              <FormLabel component="legend">
                Have you accepted Jesus Christ as your Lord and Savior?
              </FormLabel>
              <RadioGroup
                name="acceptedJesus"
                value={values.acceptedJesus}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio disabled={formDisabled} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio disabled={formDisabled} />}
                  label="No"
                />
              </RadioGroup>
              <FormTextField
                name="explanation"
                label="Please Explain"
                fullWidth
                onChange={handleChange}
                value={values.explanation}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
                MEMBERSHIP INFORMATION
              </Typography>
              <FormTextField
                name="churchHistory"
                label="How long have you been a member of New Mt. Zion?"
                fullWidth
                onChange={handleChange}
                value={values.churchHistory}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
              <FormLabel component="legend">
                Have you completed New Member Class?
              </FormLabel>
              <RadioGroup
                name="completedClass"
                value={values.completedClass}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio disabled={formDisabled} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio disabled={formDisabled} />}
                  label="No"
                />
              </RadioGroup>
              {/* <FormTextField
                name="volunteerExperience"
                label="Previous Volunteer Experience:"
                fullWidth
                onChange={handleChange}
                value={values.volunteerExperience}
                isFilled={formDisabled}
                disabled={formDisabled}
              /> */}
            </Grid>

            {values.employment && (
              <EmploymentForm
                form={values.employment}
                onChange={(f) => {
                  setValues((p) => ({ ...p, ...{ employment: f } }));
                }}
              />
            )}

            <Grid item xs={12}>
              <Typography variant="h6">
                DISCLOSURE AND AUTHORIZATION FOR BACKGROUND INFORMATION
              </Typography>
              <p>
                In connection with your volunteer work and/or employment with
                New Mt Zion Missionary Baptist Lakeland, notice is hereby given
                that a consumer report and/or investigative consumer report will
                be obtained from a consumer reporting agency for employment/
                volunteering purposes.
              </p>
              <p>
                <b>
                  Please click the link below to complete the background check
                  verification process.
                </b>
              </p>
              <p>
                <a
                  href="https://ministryopportunities.org/NMZLakeland"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://ministryopportunities.org/NMZLakeland
                </a>
              </p>
            </Grid>

            <Grid item xs={12} sm={12} sx={{ display: 'flex' }}>
              <FormTextField
                name="signature"
                label="Signature (Type in your Full Name)"
                onChange={handleChange}
                value={values.signature}
                isFilled={formDisabled}
                disabled={formDisabled}
                fullWidth
                sx={{ marginRight: 2 }}
              />
              <FormTextField
                name="signatureDate"
                label="Date"
                onChange={handleChange}
                value={values.signatureDate}
                isFilled={formDisabled}
                disabled={formDisabled}
              />
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <input
                  type="checkbox"
                  style={{ zoom: 2 }}
                  checked={read}
                  onChange={(e) => setRead(e.target.checked)}
                ></input>
                <div>
                  {' '}
                  I have Read the{' '}
                  <a href="/background-checks" target="_blank">
                    NMZ Background Verification and Training Letter
                  </a>
                </div>
              </Box>
            </Grid>
          </Grid>

          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Form submitted. You will be contacted shortly
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </Box>
  );
};

export default VolunteerForm;
