import React from 'react';
import { FormIds } from '../../services';
import { useParams } from 'react-router-dom';
import VolunteerForm from './VolunteerForm';
import SchoolBash from './SchoolBash';
import BudgetRequestForm from './BudgetRequestForm';
import BreastCancer from './BreastCancer';
import SkateWorldPermissionForm from './SkateWorldPermissionForm';
import MentalHealth from './MentalHealth';
import Bolden from './BoldenForm';

const FormDetails = () => {
  const { id } = useParams();

  if (id === FormIds.volunteer) return <VolunteerForm />;
  if (id === FormIds.schoolBash2024) return <SchoolBash />;
  if (id === FormIds.budgetForm2024) return <BudgetRequestForm />;
  if (id === FormIds.budgetForm2025) return <BudgetRequestForm />;

  if (id === FormIds.breastCancerSeminar2024) return <BreastCancer />;
  if (id === FormIds.skateworldSpringBreak) return <SkateWorldPermissionForm />;
  if (id === FormIds.mentalHealth2024) return <MentalHealth />;
  if (id === FormIds.bolden2024) return <Bolden />;
  return <></>;
};

export default FormDetails;
