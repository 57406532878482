/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  TextField,
  Typography,
  Container,
  Grid,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { EmploymentFormState as FormState } from '../../types';

function EmploymentForm({
  form,
  onChange,
}: {
  form: FormState;
  onChange: (f: FormState) => void;
}) {
  // Function to handle input changes
  const handleChange = (field: keyof FormState, value: any, index?: number) => {
    if (field === 'employment' || field === 'references') {
      const newValues = [...(form[field] as any[])];
      newValues[index!] = { ...newValues[index!], ...value };
      //setForm({ ...form, [field]: newValues });
      onChange({ ...form, [field]: newValues });
    } else {
      // setForm({ ...form, [field]: value });
      onChange({ ...form, [field]: value });
    }
  };

  // Function to handle form submission
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // Here you can handle the form submission, such as sending the data to an API
    console.log('Form data:', form);
  };

  return (
    <Container
      component="main"
      style={{
        marginTop: '2rem',
        paddingBottom: '2rem',
        border: '1px solid gray',
      }}
    >
      <Grid item xs={12} style={{ margin: '1rem' }}>
        <i>*Complete this section only if applying for employment.</i>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom>
          EMPLOYMENT INFORMATION BEGINNING WITH MOST RECENT JOB
        </Typography>
        {form.employment.map((job, index) => (
          <Box key={index} marginBottom={3}>
            <Typography variant="body1">{index + 1}.</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Employer"
                  variant="outlined"
                  value={job.employer}
                  onChange={(e) =>
                    handleChange(
                      'employment',
                      { employer: e.target.value },
                      index
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Position"
                  variant="outlined"
                  value={job.position}
                  onChange={(e) =>
                    handleChange(
                      'employment',
                      { position: e.target.value },
                      index
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Dates of employment (from)"
                  variant="outlined"
                  value={job.startDate}
                  onChange={(e) =>
                    handleChange(
                      'employment',
                      { startDate: e.target.value },
                      index
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Dates of employment (to)"
                  variant="outlined"
                  value={job.endDate}
                  onChange={(e) =>
                    handleChange(
                      'employment',
                      { endDate: e.target.value },
                      index
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}

        <Typography variant="body1" gutterBottom>
          List any Special Skills:
        </Typography>
        <TextField
          fullWidth
          label=""
          variant="outlined"
          multiline
          rows={4}
          value={form.specialSkills}
          onChange={(e) => handleChange('specialSkills', e.target.value)}
        />

        <Typography variant="h6" gutterBottom>
          VOLUNTEERS:
        </Typography>
        <TextField
          fullWidth
          label="Previous Volunteer Experience"
          variant="outlined"
          value={form.previousVolunteerExperience}
          onChange={(e) =>
            handleChange('previousVolunteerExperience', e.target.value)
          }
        />
        <Grid sx={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <TextField
            fullWidth
            label="List at least one organization that you have volunteered your time and talents."
            variant="outlined"
            value={form.previousVolunteerOrgs}
            onChange={(e) =>
              handleChange('previousVolunteerOrgs', e.target.value)
            }
          />
        </Grid>
        <Typography variant="h6" gutterBottom>
          List two references:
        </Typography>
        {form.references.map((reference, index) => (
          <Box key={index} marginBottom={3}>
            <Typography variant="body1">{index + 1}.</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={`Name`}
                  variant="outlined"
                  value={reference.name}
                  onChange={(e) =>
                    handleChange('references', { name: e.target.value }, index)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={`Email`}
                  variant="outlined"
                  value={reference.email}
                  onChange={(e) =>
                    handleChange('references', { email: e.target.value }, index)
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={`Phone number (Home)`}
                  variant="outlined"
                  value={reference.homePhone}
                  onChange={(e) =>
                    handleChange(
                      'references',
                      { homePhone: e.target.value },
                      index
                    )
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={`Cell`}
                  variant="outlined"
                  value={reference.cell}
                  onChange={(e) =>
                    handleChange('references', { cell: e.target.value }, index)
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}

        <Typography variant="body1" gutterBottom>
          Is there anything in your past or present that would prohibit you from
          effectively working with our children or students?
        </Typography>
        <RadioGroup
          row
          value={form.pastOrPresentProhibit}
          onChange={(e) =>
            handleChange('pastOrPresentProhibit', e.target.value)
          }
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <TextField
          fullWidth
          label="If yes, please explain:"
          multiline
          rows={4}
          variant="outlined"
          value={form.explanation}
          onChange={(e) => handleChange('explanation', e.target.value)}
        />
      </form>
    </Container>
  );
}

export default EmploymentForm;
