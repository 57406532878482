/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
  CircularProgress,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Container,
  InputLabel,
  Alert,
  Snackbar,
  List,
  ListSubheader,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import './customQuillStyles.css'; // Import your custom CSS file for the editor
import TextFieldWithCharacterCount from '../custom/TextFieldWithCharacterCount';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db, storage } from '../../App';
import { ComTypes, CommunicationType, User } from '../../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Recorder from './Recorder';
import { getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import WebcamRecorder from './WebcamRecorder';

Quill.register('modules/imageResize', ImageResize);

const Communication = ({ user }: { user: User }) => {
  const [comms, setComms] = useState<CommunicationType[]>([]);
  const [audioUrl, setAudioUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  const [comm, setComm] = useState<CommunicationType>({
    commType: ComTypes.Email,
    id: '',
    value: '',
    date: new Date(),
    creator: user,
    subject: '',
  });
  const [busy, setBusy] = useState(false);
  const [saving, setSaving] = useState(false);

  const [open, setOpen] = React.useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const data: any = {
        ...comm,
        date: new Date(),
        creator: {
          userId: user.userId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          profilePic: user.profilePic,
        },
      };
      if (comm.commType === ComTypes.Audio) {
        const fileName = `${Math.floor(Date.now() / 60000)}.mp4`;
        const storageRef = ref(storage, `audio/${fileName}`);
        try {
          const response = await fetch(audioUrl);
          const blob = await response.blob();
          const res = await uploadBytes(storageRef, blob, {
            contentType: 'audio/mpeg',
          });
          const url = await getDownloadURL(res.ref);
          data.audioUrl = url;
          data.fileName = fileName;
        } catch {
          console.log('');
        }
      }

      if (comm.commType === ComTypes.Video) {
        console.log(videoUrl);
        const fileName = `${Math.floor(Date.now() / 60000)}.webm`;
        const storageRef = ref(storage, `video/${fileName}`);
        try {
          const response = await fetch(videoUrl);
          const blob = await response.blob();
          const res = await uploadBytes(storageRef, blob, {
            contentType: 'video/webm',
          });
          const url = await getDownloadURL(res.ref);
          data.videoUrl = url;
          data.fileName = fileName;
        } catch {
          console.log('');
        }
      }

      const nref = doc(collection(db, 'communication'));
      await setDoc(nref, {
        ...data,
        id: nref.id,
      });
      setSaving(false);
      setComm((p) => ({ ...p, ...{ subject: '', value: '' } }));
      setAudioUrl('');
      setOpen(true);
    } catch (e) {
      alert('Unable to Save');
      setSaving(false);
    }
  };

  useEffect(() => {
    setBusy(true);
    const q = query(
      collection(db, 'communication'),
      orderBy('date', 'desc'),
      limit(20)
    );

    const unsub = onSnapshot(q, (snap) => {
      setBusy(false);
      const data: CommunicationType[] = [];
      snap.docs.forEach((doc) => {
        const d = doc.data();
        d.date = d.date.toDate();
        data.push(d as CommunicationType);
      });
      setComms(data);
    });
    return () => unsub();
  }, [user]);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          //width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <h2>Send communication messages</h2>
        <FormControl style={{ marginBottom: '10px', minWidth: '200px' }}>
          <InputLabel id="day-select-label">Communication Type</InputLabel>
          <Select
            labelId="day-select-label"
            value={comm.commType}
            onChange={(e) => {
              if (comm.commType === ComTypes.Email)
                setComm((prev) => ({ ...prev, ...{ value: '' } }));
              setComm((prev) => ({ ...prev, ...{ commType: e.target.value } }));
            }}
            displayEmpty
            required
            label="Communication Type"
          >
            <MenuItem value="" disabled>
              Communication Type
            </MenuItem>
            {[
              ComTypes.Email,
              ComTypes.Text,
              ComTypes.Audio,
              ComTypes.Video,
            ].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {comm.commType === ComTypes.Email && (
          <TextField
            label="Subject"
            value={comm.subject}
            onChange={(x) =>
              setComm((prev) => ({ ...prev, ...{ subject: x.target.value } }))
            }
            required
            style={{ marginBottom: '10px', minWidth: '300px' }}
          />
        )}
        {(comm.commType === ComTypes.Email ||
          comm.commType === ComTypes.Text) && <InputLabel>Message</InputLabel>}
        {comm.commType === ComTypes.Email && (
          <ReactQuill
            theme="snow"
            value={comm.value}
            onChange={(x) => setComm((prev) => ({ ...prev, ...{ value: x } }))}
            modules={modules}
            formats={formats}
            style={{ width: '80%' }}
            className="custom-quill-editor"
          />
        )}
        {comm.commType === ComTypes.Text && (
          <TextFieldWithCharacterCount
            value={comm.value}
            onChange={(x) =>
              setComm((prev) => ({ ...prev, ...{ value: x.target.value } }))
            }
            multiline
            rows={4}
            style={{ marginBottom: '10px', minWidth: '300px' }}
            required
            maxLength={140}
          />
        )}

        {comm.commType === ComTypes.Audio && (
          <Recorder audioUrl={audioUrl} onRecord={setAudioUrl} />
        )}
        {comm.commType === ComTypes.Video && (
          <WebcamRecorder url={videoUrl} onRecord={setVideoUrl} />
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={busy ? <CircularProgress size={20} /> : null}
          disabled={
            busy ||
            saving ||
            ((comm.commType === ComTypes.Email ||
              comm.commType === ComTypes.Text) &&
              !comm.value) ||
            (comm.commType === ComTypes.Email && !comm.subject) ||
            (comm.commType === ComTypes.Audio && !audioUrl) ||
            (comm.commType === ComTypes.Video && !videoUrl)
          }
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          {saving ? 'Sending...' : 'Send'}
        </Button>
        <List
          subheader={
            <ListSubheader sx={{ mt: 5 }}>Recent Communications</ListSubheader>
          }
          sx={{ width: '80%' }}
        >
          {comms.map((item) => (
            <Accordion key={item.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography>
                    {item.commType === ComTypes.Email
                      ? item.subject
                      : `${item.commType} Message`}
                  </Typography>
                  <Typography color="GrayText">
                    {item.date.toLocaleString()}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: item.value }} />
                {item.commType === ComTypes.Audio && (
                  <div>
                    <audio src={item.audioUrl} controls />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => {
            setOpen(false);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="success"
            sx={{ width: '100%' }}
          >
            Communication Sent Out!
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Communication;
