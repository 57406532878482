// src/components/About.tsx

import { AccessTime, Email, People, Phone, PinDrop } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import React, { useState } from 'react';
import { submitContactRequest } from '../../services';
import './index.css';

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const options = [
    'New Members',
    'Photo Release',
    'Prayer Requests',
    'Benevolence',
    'Apply for facility use',
    'Other',
  ];
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    submitContactRequest({
      firstName,
      lastName,
      email,
      phone,
      note,
      selectedOption,
    })
      .then(() => {
        setOpen(true);
        setIsLoading(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSelectedOption('');
        setNote('');
      })
      .catch(() => {
        setIsLoading(false);
        alert('Error Submitting');
      });
  };
  return (
    <div className="contactus">
      <div className="contactus-hero">Contact Us</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexFlow: 'wrap',
          justifyContent: 'center',
          margin: '2rem',
        }}
      >
        <Card sx={{ padding: 2, margin: 1, width: { xs: '100%', md: '33%' } }}>
          <CardContent className="contactus-card">
            <PinDrop fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Our Location </div>
            <div>1321 N. Webster Ave. Lakeland, FL 33805</div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1, width: { xs: '100%', md: '33%' } }}>
          <CardContent className="contactus-card">
            <Phone fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>NMZ Lakeland</div>
            <div>(863) 686-6793</div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1, width: { xs: '100%', md: '33%' } }}>
          <CardContent className="contactus-card">
            <Email fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>Email Address</div>
            <div>info@nmzlakeland.org</div>
          </CardContent>
        </Card>
        <Card
          sx={{
            padding: 2,
            margin: 1,
            backgroundColor: '#BF0A30',
            color: 'white',
            width: { xs: '100%', md: '33%' },
          }}
        >
          <CardContent className="contactus-card">
            <AccessTime fontSize="large" />
            <div style={{ fontWeight: 'bold' }}>OFFICE HOURS</div>
            <div>Monday - Thursday: 9am - 5pm</div>
            <div>Friday - Sunday: Closed</div>
          </CardContent>
        </Card>
        <Card sx={{ padding: 2, margin: 1, width: { xs: '100%', md: '50%' } }}>
          <CardContent className="contactus-card">
            <People fontSize="large" />
            {/* <div style={{ fontWeight: 'bold' }}>Staff</div> */}
            <div>
              <b>Church Information</b>: (863) 940-9541
            </div>
            <div>
              <b>Financial Secretary</b>: (863) 940-9535
            </div>
            <div>
              <b>Office Supervisor</b>:(863) 940-9534
            </div>
            <div>
              <b>Business Manager</b>:(863) 940-9541
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        <h2>Connect with NMZ Lakeland</h2>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: {
                md: '50vw',
                xs: '90vw',
              },
              marginBottom: 20,
            }}
          >
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              style={{ marginBottom: '10px' }}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              style={{ marginBottom: '10px' }}
              inputProps={{
                inputMode: 'email',
                pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
                title: 'Please enter a valid email address',
              }}
            />
            <TextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
              required
              style={{ marginBottom: '10px' }}
            />

            <FormControl style={{ marginBottom: '10px' }}>
              <Select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                displayEmpty
                required
              >
                <MenuItem value="" disabled>
                  Reason for your Request{' '}
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Please take a moment to tell us a little more about your request"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              multiline
              rows={4}
              style={{ marginBottom: '10px' }}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </form>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          Message Sent. You will be contacted very soon
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;
