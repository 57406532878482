import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Announcement, useAnnouncements } from '../../services';

const AnnouncementBanner = () => {
  const nav = useNavigate();
  const { announcements: anns } = useAnnouncements();
  const [ann, settAnn] = useState<Announcement | null>(null);

  useEffect(() => {
    if (anns.length === 0) return;

    settAnn(anns[0]);
    const t = setInterval(() => {
      settAnn((p) => {
        if (!p) return anns[0];
        let index = anns.findIndex((x) => x.id === p.id);
        index++;
        if (index >= anns.length) index = 0;
        return anns[index];
      });
    }, 5000);
    return () => {
      clearInterval(t);
    };
  }, [anns]);

  if (!ann) return null;

  return (
    <Box
      sx={{
        backgroundColor: '#fffbcc',
        padding: '1rem',
        //color: '#777',
        textAlign: 'center',
        fontSize: '0.8rem',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Box sx={{ flex: { xs: 1, md: 'none' } }}>{ann.text}</Box>
      {ann?.link && (
        <Button
          sx={{ ml: '1rem', marginRight: '1rem' }}
          onClick={() =>
            ann?.link?.startsWith('https')
              ? window.open(ann.link, '_blank')
              : nav(ann?.link ?? '/', {})
          }
        >
          {ann.callToAction}
        </Button>
      )}
    </Box>
  );
};

export default AnnouncementBanner;
