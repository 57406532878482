import React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { churchMinistries } from '../services';
import { colors } from '../utils';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

enum Mode {
  preview,
  page,
}
const Ministries = () => {
  const location = useLocation();
  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;

  const mode = location.pathname === '/' ? Mode.preview : Mode.page;

  const settings = {
    dots: false,
    //infinite: true,
    //speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    //slidesToScroll: 1,
    //arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // useEffect(() => {
  //   if (mode === Mode.preview) {
  //     const interval = setInterval(() => {
  //       // Call your function here
  //       handleNext();
  //     }, 10000); // 10000 milliseconds = 10 seconds
  //     // Clean up the interval on component unmount
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [handleNext, mode]);

  const boxes = () =>
    churchMinistries.map((m) => (
      <div key={m.id}>
        <Card
          key={m.id}
          sx={{
            margin: 1,
            height: 'auto',
            width: '95%',

            // width: {
            //   xs: '100%',
            //   md: '45%',
            // },
          }}
        >
          {/* <Link to={`/ministries/${m.id}`}> */}
          <CardMedia
            component="img"
            className="clickable-card-image"
            height={isMobile ? '200' : '250'}
            // width="250"
            image={require(`../images/ministries/${m.media[0]}`)}
            alt={m.name}
          />
          {/* </Link> */}
          <CardContent>
            <Box
              sx={{
                fontWeight: 'bold',
                //margin: 1,
                fontSize: { md: '1.3rem', xs: '1rem' },
                height: '40px',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {m.name}
            </Box>

            <div
              style={{
                color: '#888',
                textAlign: 'initial',
                marginBottom: 15,
                height: '40px',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {m.description}
            </div>
            <Button
              variant="outlined"
              color="primary"
              href={`/ministries/${m.id}`}
              style={{
                borderColor: colors.themeRed,
                color: colors.themeRed,
              }}
            >
              Learn More
            </Button>
          </CardContent>
        </Card>
      </div>
    ));
  return (
    <div
      className="ministries"
      style={{ background: mode === Mode.preview ? colors.themeRed : 'none' }}
    >
      {mode === Mode.page && (
        <div className="contactus-hero">Our Ministries</div>
      )}
      {mode === Mode.preview && (
        <div
          className="ministry-header"
          style={{ color: 'white', marginTop: '2rem' }}
        >
          Our Ministries
        </div>
      )}
      {mode !== Mode.preview && <div className="ministry-items">{boxes()}</div>}

      {mode === Mode.preview && (
        <>
          <div
            style={{
              width: isMobile ? '75vw' : '85vw',
              backgroundColor: colors.themeRed,
              padding: '40px',
            }}
          >
            <Slider {...settings}>{boxes()}</Slider>
          </div>
          <Button
            variant="contained"
            href="/ministries"
            color="primary"
            style={{
              color: colors.themeRed,
              margin: '20px',
              backgroundColor: 'white',
            }}
          >
            View all Ministries
          </Button>
        </>
      )}
    </div>
  );
};

export default Ministries;
