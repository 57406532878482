import React, { useState } from 'react';
import {
  Button,
  Box,
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { DocumentData } from '@google-cloud/firestore';
import { FormIds, submitForm } from '../../services';
import { Print } from '@mui/icons-material';
import { FormTextField } from '../custom/CustomComponents';

export interface IBreastFormData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}

const MentalHealth = ({ form = null }: { form?: DocumentData | null }) => {
  const targetDate = new Date('2024-05-03T10:00:00');
  const currentDate = new Date();
  const expired = currentDate.getTime() < targetDate.getTime();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState<IBreastFormData>(
    (form || {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
    }) as IBreastFormData
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  //   const handleChange2 = (e: SelectChangeEvent<string>) => {
  //     const { name, value } = e.target;
  //     setState({
  //       ...state,
  //       [name]: value,
  //     });
  //   };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const sanitizedData = {
      ...state,
      ...{ firstName: state.firstName.trim(), lastName: state.lastName.trim() },
    };
    submitForm(FormIds.mentalHealth2024, sanitizedData)
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { firstName, lastName } = state;
  const isDisabled = !firstName || !lastName;
  const formDisabled = form !== null;

  if (expired) {
    return (
      <Dialog
        open={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Registration Capacity Reached{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for your interest in registering for our 2024 Mental
            Health Symposium. We have reach capacity and registration is now
            closed. We hope to see you next year. Blessings to you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = '/';
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Box sx={{ margin: '2rem' }}>
      <Container
        sx={{
          width: {
            md: '50%',
            xs: '100%',
          },
        }}
      >
        {formDisabled && (
          <div className="no-print">
            <Button variant="contained" startIcon={<Print />} onClick={print}>
              Print Completed Form
            </Button>
          </div>
        )}

        <h2>Community Mental Health Symposium</h2>
        <div style={{ color: 'gray', marginTop: '-1rem' }}>
          (May 4 2024 8:30 a.m - 11:30 a.m)
        </div>

        <Box
          sx={{
            display: formDisabled ? 'none' : 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a
            href={require(`../../images/mental_awareness.pdf`)}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require(`../../images/mental_1.png`)}
              className="ministry-details-image"
              style={{
                // height: '300px',
                width: '100%',
                maxWidth: '300px',
                margin: '1rem',
                cursor: 'pointer',
                border: '3px solid black',
              }}
            />
          </a>
          <div>
            <a
              href={require(`../../images/mental_awareness.pdf`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Flyer
            </a>
          </div>
        </Box>
        <h2>Registration form</h2>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                disabled={formDisabled}
                fullWidth
                margin="normal"
                name="firstName"
                label="First Name"
                value={state.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                disabled={formDisabled}
                fullWidth
                margin="normal"
                name="lastName"
                label="Last Name"
                value={state.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                disabled={formDisabled}
                fullWidth
                margin="normal"
                name="address"
                label="ADDRESS"
                value={state.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormTextField
                disabled={formDisabled}
                fullWidth
                margin="normal"
                name="city"
                label="CITY"
                value={state.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormTextField
                disabled={formDisabled}
                name="state"
                label="STATE"
                value={state.state}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormTextField
                disabled={formDisabled}
                name="zip"
                label="ZIP"
                value={state.zip}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                disabled={formDisabled}
                fullWidth
                margin="normal"
                name="email"
                label="E-MAIL"
                value={state.email}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Form submitted
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </Box>
  );
};

export default MentalHealth;
