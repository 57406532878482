/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Intro from './Intro';
import Ministries from './Ministries';
import Sermons from './Sermons';
import DonateBanner from './DonateBanner';
import AnnouncementBanner from './Announcement/AnnouncementBanner';
import WhatsHappening from './Events/WhatsHappening';
import { Box, Button } from '@mui/material';

const Step = ({ value, width = '30%' }: { value: string; width?: string }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#800080',
        color: '#ffc000',
        padding: 0,
        fontSize: { xs: '1rem', md: '1.5rem' },
        width,
        mt: 2,
        mb: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <div>{value.toUpperCase()}</div>
    </Box>
  );
};

const Text = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff3cc',
        color: '#ff0000',
        flex: 1,
        padding: 2,
        fontSize: { xs: '1rem', md: '1.5rem' },
        mt: 2,
        mb: 2,
        fontWeight: 'bold',
        whiteSpace: 'break-spaces',
      }}
    >
      {value}
    </Box>
  );
};

const info = [
  {
    step: 'step one',
    text: `*New Budget process begins with Pastoral Vision and Goals and \n*Ministry goals and budget requests`,
  },
  {
    step: 'step two',
    text: `Development of the proposed budget by the budget committee`,
  },
  {
    step: 'step three',
    text: `Proposed Budget presented to Pastor, Trustees, Deacons, & Finance Committee For input and approval`,
  },
  {
    step: 'step four',
    text: `Proposed Budget presented at NMZ Business Meeting for final approval`,
  },
  {
    step: 'step five',
    text: `2024 BUDGET implemented on January 1, 2024`,
  },
];
const BudgetSteps = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        m: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffcc00',
          padding: 0,
          width: { md: '80%', xs: '100%', lg: '50%' },
          m: 2,
          border: '5px solid #5e0483',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Step value="OUR 2024 BUDGET PROCESS" width={'100%'} />
        {info.map(({ step, text }) => (
          <Box key={step} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Step value={step} />
            <Text value={text} />
          </Box>
        ))}
      </Box>
      <Button variant="outlined" href="/forms/budget-request-form-2024">
        LEARN MORE AND OBTAIN FORMS
      </Button>
    </Box>
  );
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Home = () => {
  return (
    <div>
      <AnnouncementBanner />
      {/* <img src={require('./../images/be_safe.png')} style={{ width: '100%' }} /> */}

      <Intro />
      <Sermons />
      <WhatsHappening />
      {/* <BudgetSteps /> */}
      <Ministries />

      <DonateBanner />
    </div>
  );
};

export default Home;
