/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Container,
  Grid,
  CircularProgress,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FormIds, submitForm } from '../../services';
import { RemoveCircle } from '@mui/icons-material';
import { DocumentData } from '@google-cloud/firestore';
import { FormTextField } from '../custom/CustomComponents';
import SignatureCanvas from 'react-signature-canvas';

interface Child {
  firstName: string;
  lastName: string;
  age: string;
  allergies?: string;
  medications?: string;
}
const ChildInputs = ({
  index,
  childState,
  handleChildInputChange,
  handleDeleteChild,
  isFilled,
}: {
  index: number;
  childState: Child;
  handleChildInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteChild: (index: number) => void;
  isFilled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        mr: 1,
        ml: 1,
        mt: 1,
        border: {
          xs: '1px solid #ccc',
          md: 'none',
        },
      }}
    >
      <FormTextField
        label={`Child's First Name`}
        name={`firstName${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.firstName}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's Last Name`}
        name={`lastName${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.lastName}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Child's Age`}
        name={`age${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.age}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Allergies`}
        name={`allergies${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.allergies}
        isFilled={isFilled}
        disabled={isFilled}
      />
      <FormTextField
        label={`Medications`}
        name={`medications${index}`}
        onChange={handleChildInputChange}
        variant="outlined"
        sx={{ m: 1 }}
        value={childState.medications}
        isFilled={isFilled}
        disabled={isFilled}
      />

      {!isFilled && (
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteChild(index)}
        >
          <RemoveCircle />
        </IconButton>
      )}
    </Box>
  );
};

const SkateWorldPermissionForm = ({
  form = null,
}: {
  form?: DocumentData | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const kids = !form
    ? [
        {
          firstName: '',
          lastName: '',
          age: '',
          allergies: '',
          medications: '',
        },
      ]
    : form.children;
  const [children, setChildren] = useState<Child[]>(kids);

  const addChild = () => {
    setChildren([
      ...children,
      {
        firstName: '',
        lastName: children[0].lastName,
        age: '',
        allergies: '',
        medications: '',
      },
    ]);
  };

  const signatureCanvasRef = useRef(null);

  const clearSignature = () => {
    if (!signatureCanvasRef?.current) return;
    (signatureCanvasRef.current as any).clear();
  };

  const getSignature = () => {
    if (!signatureCanvasRef?.current) return '';
    return (signatureCanvasRef.current as any).toDataURL();
  };

  const [initialSignature, setInitialSignature] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setInitialSignature(getSignature());
  }, []);

  const handleChildInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedChildren = [...children];
    const prop = e.target.name.replace(`${index}`, '');
    if (prop === 'firstName') updatedChildren[index].firstName = e.target.value;
    if (prop === 'lastName') updatedChildren[index].lastName = e.target.value;
    if (prop === 'age') updatedChildren[index].age = e.target.value;
    if (prop === 'allergies') updatedChildren[index].allergies = e.target.value;
    if (prop === 'medications')
      updatedChildren[index].medications = e.target.value;

    setChildren(updatedChildren);
  };

  const handleDeleteChild = (index: number) => {
    const newChildren = [...children];
    newChildren.splice(index, 1);
    if (newChildren.length === 0) return;
    setChildren(newChildren);
  };
  const [values, setValues] = useState({
    lastName: form?.lastName ?? '',
    firstName: form?.firstName ?? '',
    cellPhone: form?.cellPhone ?? '',
    agreed: form?.agreed ?? false,
    signature: form?.signature ?? '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const signature = getSignature();
    if (signature === initialSignature) {
      alert('Please sign the Signature box');
      return;
    }
    setIsLoading(true);

    submitForm(FormIds.skateworldSpringBreak, {
      ...values,
      ...{ signature, ministry: 'youth' },
      ...{ children },
    })
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        alert('Error Submitting');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const { firstName, lastName, cellPhone, agreed } = values;
  const isDisabled = !(
    firstName &&
    lastName &&
    cellPhone &&
    agreed &&
    // signature &&
    children.every((x) => x.firstName && x.age)
  );

  return (
    <Box sx={{ margin: '2rem' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h3>
          NEW MT. ZION MISSIONARY BAPTIST CHURCH LAKELAND PERMISSION FORM{' '}
        </h3>

        <h4 style={{ marginTop: 0 }}>
          <u>
            Skate World Christian Skate Night (March 13th 2024, 5:30pm - 8:00pm)
          </u>
        </h4>
        {!form && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <a
              href={require(`../../images/skate_world_flyer.png`)}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require(`../../images/skate_world_flyer.png`)}
                className="ministry-details-image"
                style={{
                  height: '300px',
                  width: 'auto',
                  margin: '1rem',
                  cursor: 'pointer',
                  border: '3px solid black',
                }}
              />
            </a>
            <div>
              Please fill and submit the form below or{' '}
              <a
                href={require(`../../images/skate_world_permission.pdf`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
              {` `}to Print out and manually fill
            </div>
          </Box>
        )}

        <h4>{`Enter your Children's Information`}</h4>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Grid>
                {children.map((x, index) => (
                  <ChildInputs
                    key={index}
                    index={index}
                    handleChildInputChange={(x) =>
                      handleChildInputChange(x, index)
                    }
                    handleDeleteChild={handleDeleteChild}
                    childState={x}
                    isFilled={form !== null}
                  />
                ))}
              </Grid>
              {!form && (
                <Button onClick={addChild} sx={{ ml: 3, mt: { xs: 1, md: 1 } }}>
                  Add another Child
                </Button>
              )}
            </Box>
            <Grid item xs={12} sm={12} md={4}>
              <FormTextField
                name="firstName"
                label="Parent/Guardian First Name"
                fullWidth
                onChange={handleChange}
                value={values.firstName}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormTextField
                name="lastName"
                label="Parent/Guardian Last Name"
                fullWidth
                onChange={handleChange}
                value={values.lastName}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormTextField
                name="cellPhone"
                label="Emergency Contact Phone Number"
                fullWidth
                onChange={handleChange}
                value={values.cellPhone}
                isFilled={form !== null}
                disabled={form !== null}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ lineHeight: '1.5rem' }}>
                As the parent/guardian, I hereby give consent for my
                child/children to attend SKATE WORLD with the Youth Department
                sponsored by New Mount Zion Missionary Baptist Church on March
                13, 2024. My daughter/son and I understand that he/she is to
                comply with all rules and regulations by all representatives of
                New Mount Zion M. B. Church. I understand that precautions will
                be taken to ensure my child’s safety. I, therefore, will not
                hold the New Mount Zion Missionary Baptist Church in Lakeland,
                FL or any representatives of New Mount Zion Missionary Baptist
                Church in Lakeland, FL responsible for any complication, injury,
                or illness experienced by my child.
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={agreed}
                  onChange={(x) =>
                    setValues((p) => ({ ...p, agreed: x.target.checked }))
                  }
                />
                <div>I Agree to the above</div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div>Sign below</div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ border: '1px solid black' }}>
                <SignatureCanvas
                  ref={signatureCanvasRef}
                  penColor="black"
                  canvasProps={{
                    width: 300,
                    height: 60,
                    className: 'signature-canvas',
                  }}
                />
              </div>
              <div>
                <Button onClick={clearSignature}>Clear</Button>
              </div>
            </Grid>
          </Grid>
          {!form && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '1em' }}
              disabled={isDisabled || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}

          <Dialog
            open={showModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Form Submitted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thank you for filling out this form
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Container>
    </Box>
  );
};

export default SkateWorldPermissionForm;
