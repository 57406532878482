import React, { useState, ChangeEvent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldWithCharacterCountProps = TextFieldProps & { maxLength: number };

const TextFieldWithCharacterCount: React.FC<
  TextFieldWithCharacterCountProps
> = ({ maxLength, ...props }) => {
  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCharacterCount(value.length);
    if (props.onChange) props.onChange(event);
  };

  return (
    <div>
      <TextField
        {...props}
        inputProps={{ maxLength }}
        onChange={handleChange}
      />
      <div style={{ color: 'gray', fontSize: '0.8rem' }}>
        Character count: {characterCount}/{maxLength}
      </div>
    </div>
  );
};

export default TextFieldWithCharacterCount;
